import React, { useEffect, useState } from 'react';
import styles from '../styles/archiveui.module.css';
import { withRouter } from 'react-router';
import { AuditEntry, AuditFilterEntry } from '../types/audit';
import AuditLogTable from './auditlogTable';
import { LoadingComponent } from '../Loading';
import { fetchAuditFilters, searchAuditLog } from '../api/audit';
import ExportAuditData from './exportdata';
import { useUserContext } from '../contexts/UserContext';
import { generateAuditQuery } from './querygeneration';

const AuditLog = (): JSX.Element => {
  const { currentAccount } = useUserContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortString, setSortString] = useState([]);
  const [entries, setAuditEntries] = useState<AuditEntry[]>([]);
  const [auditFilter, setAuditFilters] = useState<AuditFilterEntry>({ userFilter: [], actionFilter: [] });
  const [userFilter, updateUserFilter] = useState<string>('');
  const [actionFilter, updateActionFilter] = useState<string>('');
  const [startDate, updateStartDate] = useState<string>('');
  const [endDate, updateEndDate] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const fetchAudits = async () => {
    const result = await searchAuditLog(currentAccount.accountId, '*', currentPage, pageSize, sortString);
    const filterResult = await fetchAuditFilters(currentAccount.accountId);
    setTotalCount(result.count);
    setAuditEntries(result.entries);
    setAuditFilters(filterResult.filters);
    setLoading(false);
  };

  const exportAuditData = async () => {
    let startDateFilter;
    let endDateFilter;
    if (startDate) {
      startDateFilter = new Date(startDate);
    }
    if (endDate) {
      endDateFilter = new Date(endDate);
    }
    const filterQuery = generateAuditQuery(startDateFilter, endDateFilter, userFilter, actionFilter);
    const exportData = await searchAuditLog(currentAccount.accountId, filterQuery, 1, 1000);
    ExportAuditData(exportData.entries);
  };

  const filterAuditData = async (reset: boolean) => {
    if (((!userFilter || userFilter === '') && (!actionFilter || actionFilter === '') && !startDate && !endDate) || reset) {
      await fetchAudits();
    } else {
      let startDateFilter;
      let endDateFilter;
      if (startDate) {
        startDateFilter = new Date(startDate);
      }
      if (endDate) {
        endDateFilter = new Date(endDate);
      }
      const filterQuery = generateAuditQuery(startDateFilter, endDateFilter, userFilter, actionFilter);
      const result = await searchAuditLog(currentAccount.accountId, filterQuery, currentPage, pageSize, sortString);
      setTotalCount(result.count);
      setAuditEntries(result.entries);
    }
  };

  useEffect(() => {
    fetchAudits();
  }, [currentPage, pageSize, sortString]);

  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <LoadingComponent isLoading={loading}>
      <div className={styles.customLogContainer}>
        <AuditLogTable
          audits={entries}
          filters={auditFilter}
          totalCount={totalCount}
          updateUserFilter={updateUserFilter}
          updateActionFilter={updateActionFilter}
          updateStartDate={updateStartDate}
          updateEndDate={updateEndDate}
          userFilter={userFilter}
          actionFilter={actionFilter}
          startDate={startDate}
          endDate={endDate}
          exportAuditData={exportAuditData}
          filterAuditData={filterAuditData}
          totalPages={totalPages}
          controlledPage={currentPage}
          selectedPageSize={pageSize}
          updatePageSize={setPageSize}
          updatePage={setCurrentPage}
          updateSort={setSortString}
        />
      </div>
    </LoadingComponent>
  );
};

export default withRouter(AuditLog);
