import React from 'react';
import { Modal, ModalHeader, Form, ModalBody, ModalFooter, Button } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';

interface ConfirmModalProps {
  modal: boolean;
  toggle: () => void;
  message: string;
  onConfirm?: () => void;
}

const ConfirmationModal = ({ modal, toggle, message, onConfirm }: ConfirmModalProps): JSX.Element => {
  return (
    <div>
      <Modal toggle={toggle} isOpen={modal} style={{ minWidth: '40em' }}>
        <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
        <Form>
          <ModalBody>
            <div>{message}</div>
          </ModalBody>
        </Form>
        <ModalFooter>
          {onConfirm && (
            <>
              <Button className={styles.scrbBtnBlue} onClick={toggle}>
                Cancel
              </Button>{' '}
            </>
          )}
          <Button
            className={styles.scrbBtnOrange}
            type="button"
            onClick={
              onConfirm
                ? (e) => {
                    e.preventDefault();
                    onConfirm();
                  }
                : toggle
            }
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;
