import React, { useState, useEffect, ChangeEvent } from 'react';
import styles from '../../styles/archiveui.module.css';
import { Col, Container, Form, Input, Row } from 'reactstrap';
import SearchableDropdown from './SearchableDropdown';
import { fetchFilters } from '../../api/document';
import { useUserContext } from '../../contexts/UserContext';
import { StatusInput } from '../documentsearch/statusinput';
import { CRM_DISPLAY_OPTIONS, DocumentTypeOptions } from '../../types/document';

interface AdvancedFilterComponentProps {
  onUpdate: (field: string, value: string[]) => void;
  updateStatusSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  statusSearch: string;
}
const AdvancedFilterComponent = ({ onUpdate, updateStatusSearch, statusSearch }: AdvancedFilterComponentProps) => {
  const { currentAccount } = useUserContext();
  const [firstNames, setFirstNames] = useState<string[]>([]);
  const [lastNames, setLastNames] = useState<string[]>([]);
  const [districts, setDistricts] = useState<string[]>([]);
  const [schools, setSchools] = useState<string[]>([]);
  const [docTypes, setDocTypes] = useState<string[]>([]);

  const handleFirstNameSelect = (items: string[]) => {
    onUpdate('firstNames', items);
  };

  const handleLastNameSelect = (items: string[]) => {
    onUpdate('lastNames', items);
  };

  const handleDistrictSelect = (items: string[]) => {
    onUpdate('districts', items);
  };

  const handleSchoolSelect = (items: string[]) => {
    onUpdate('schools', items);
  };

  const handleDocTypeSelect = (items: string[]) => {
    onUpdate('docTypes', items);
  };

  const handleCRMStatusSelect = (items: string[]) => {
    onUpdate('crmStatus', items);
  };

  useEffect(() => {
    fetchFiltersInternal();
  }, []);

  const fetchFiltersInternal = async () => {
    try {
      const fetchResults = await fetchFilters(currentAccount.accountId);
      const { filters } = fetchResults || {};
      if (filters) {
        const { firstNames, lastNames, districts, schools } = filters;
        setFirstNames(firstNames);
        setLastNames(lastNames);
        setDistricts(districts);
        setSchools(schools);
        setDocTypes(DocumentTypeOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container className={`${styles.documentAdvancedFilterContainer}`}>
      <Form>
        <Row style={{ marginBottom: '.6em' }}>
          <Col sm="3">
            <h5>Status</h5>
            <StatusInput onChange={updateStatusSearch} defaultValue={statusSearch} showAll={true} />
          </Col>
          <Col sm="3">
            <h5>Order ID</h5>
            <Input
              onChange={(e) => {
                onUpdate('orderId', [e.target.value]);
              }}
            />
          </Col>
          <Col sm="3">
            <h5>Application ID</h5>
            <Input
              onChange={(e) => {
                onUpdate('appId', [e.target.value]);
              }}
            />
          </Col>
          <Col sm="3">
            <h5>Student ID</h5>
            <Input
              onChange={(e) => {
                onUpdate('studentId', [e.target.value]);
              }}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: '.6em' }}>
          <Col sm="3">
            <h5>First Name</h5>
            <SearchableDropdown items={firstNames} onSelect={handleFirstNameSelect} />
          </Col>
          <Col sm="3">
            <h5>Last Name</h5>
            <SearchableDropdown items={lastNames} onSelect={handleLastNameSelect} />
          </Col>
          <Col sm="3">
            <h5>District</h5>
            <SearchableDropdown items={districts} onSelect={handleDistrictSelect} />
          </Col>
          <Col sm="3">
            <h5>School</h5>
            <SearchableDropdown items={schools} onSelect={handleSchoolSelect} />
          </Col>
        </Row>
        <Row style={{ marginBottom: '.6em' }}>
          <Col sm="3">
            {currentAccount.crmStatus && currentAccount.crmStatus !== 'DISABLED' && (
              <>
                <h5>Send to CRM</h5>
                <SearchableDropdown items={CRM_DISPLAY_OPTIONS} onSelect={handleCRMStatusSelect} />
              </>
            )}
          </Col>
          <Col sm="3">
            <h5>Doc Type</h5>
            <SearchableDropdown items={docTypes} onSelect={handleDocTypeSelect} />
          </Col>
          <Col sm="3">
            <h5>HS CEEB</h5>
            <Input
              onChange={(e) => {
                onUpdate('metaData.hs_ceeb', [e.target.value]);
              }}
            />
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
export default AdvancedFilterComponent;
