import React, { FormEvent, useState, useEffect } from 'react';
import { getRoles } from '../../api/roles';
import { Modal, ModalHeader, Form, ModalBody, Row, Col, FormGroup, Label, Input, FormFeedback, ModalFooter, Button } from 'reactstrap';
import { createUsersForAccount } from '../../api/users';
import { toast } from 'react-toastify';
import { PermissionAssociation, Role } from '../../types/role';
import styles from '../../styles/archiveui.module.css';

interface UserInviteModalProps {
  modal: boolean;
  toggle: () => void;
  accountId: string;
  fetchUsers?: () => Promise<void>;
}

type FormError = { email: string; roleId: string };

const UserInviteModal = ({ modal, toggle, accountId, fetchUsers }: UserInviteModalProps) => {
  const permissionsAssociations: PermissionAssociation[] = [];
  const [roles, updateRoles] = useState<Role[]>([]);
  const [invitedUser, setInvitedUser] = useState({
    invitedUserEmail: '',
    invitedUserRoleId: '',
    invitedUserRole: {
      id: '',
      accountId: '',
      name: '',
      description: '',
      created: new Date(),
      modified: new Date(),
      permissions: permissionsAssociations,
    },
  });
  const [formErrors, setFormErrors] = useState<FormError>({ email: '', roleId: '' });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const addToInvitedUser = (key: string, value: any) => {
    setInvitedUser((prevValues) => {
      return { ...prevValues, [key]: value };
    });
  };

  useEffect(() => {
    const setRoles = async () => {
      const roles = await getRoles(accountId);
      updateRoles(roles);
    };
    setRoles();
  }, []);

  const validate = (email: string, roleId: string) => {
    const errors = {
      email: '',
      roleId: '',
    };
    let valid = true;

    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email.trim(),
      )
    ) {
      errors.email = 'Email provided is not a valid email';
      valid = false;
    }
    if (roleId.trim().length < 1) {
      errors.roleId = 'You must select a role';
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  const _inviteUserHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validate(invitedUser.invitedUserEmail, invitedUser.invitedUserRoleId)) {
      return;
    }
    await toast.promise(createUsersForAccount(accountId, { email: invitedUser.invitedUserEmail }, [invitedUser.invitedUserRoleId]), {
      pending: 'Inviting User',
      success: 'User Invited',
      error: 'We were unable to invite this user',
    });
    if (fetchUsers) await fetchUsers();
    toggle();
  };

  const inviteUserHandler = async (event: FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    try {
      await _inviteUserHandler(event);
    } catch (e) {
      toast.error('Unable to invite this user, please try again.');
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Modal toggle={toggle} isOpen={modal} style={{ minWidth: '40em' }}>
        <ModalHeader style={{ backgroundColor: '#ceeaf4' }} toggle={toggle}>
          Invite User
        </ModalHeader>
        <Form
          onSubmit={(event) => {
            inviteUserHandler(event);
          }}
        >
          <ModalBody style={{ backgroundColor: '#F4F4F4' }}>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    required
                    invalid={formErrors.email !== ''}
                    onChange={(e) => {
                      addToInvitedUser('invitedUserEmail', e.target.value);
                    }}
                  />
                  <FormFeedback>{formErrors.email}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="description">Role</Label>
                  <Input
                    required
                    invalid={formErrors.roleId !== ''}
                    type="select"
                    onChange={(e) => {
                      addToInvitedUser('invitedUserRoleId', e.target.value);
                    }}
                  >
                    <option></option>
                    {roles.map((role, key) => {
                      return (
                        <option key={key} value={role.id}>
                          {role.name}
                        </option>
                      );
                    })}
                  </Input>
                  <FormFeedback>{formErrors.roleId}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter style={{ backgroundColor: '#F4F4F4' }}>
            <Button className={styles.scrbBtnBlue} onClick={toggle}>
              Cancel
            </Button>{' '}
            <Button type="submit" disabled={isSubmitting} className={styles.scrbBtnOrange}>
              Send Invitation
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default UserInviteModal;
