import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { DeliveryScheduleType } from '../types/crmintegration/config';
import { Button, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import MultiSelect, { Option } from '../genericcomponents/multiselect';

interface CRMConfigProps {
  scheduleType: DeliveryScheduleType;
  selectedTimes: string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTimeChange: (times: string[]) => void;
}

const timeOptions: Option[] = [
  { key: '00:00', displayValue: '12:00 AM' },
  { key: '00:30', displayValue: '12:30 AM' },
  { key: '01:00', displayValue: '1:00 AM' },
  { key: '01:30', displayValue: '1:30 AM' },
  { key: '02:00', displayValue: '2:00 AM' },
  { key: '02:30', displayValue: '2:30 AM' },
  { key: '03:00', displayValue: '3:00 AM' },
  { key: '03:30', displayValue: '3:30 AM' },
  { key: '04:00', displayValue: '4:00 AM' },
  { key: '04:30', displayValue: '4:30 AM' },
  { key: '05:00', displayValue: '5:00 AM' },
  { key: '05:30', displayValue: '5:30 AM' },
  { key: '06:00', displayValue: '6:00 AM' },
  { key: '06:30', displayValue: '6:30 AM' },
  { key: '07:00', displayValue: '7:00 AM' },
  { key: '07:30', displayValue: '7:30 AM' },
  { key: '08:00', displayValue: '8:00 AM' },
  { key: '08:30', displayValue: '8:30 AM' },
  { key: '09:00', displayValue: '9:00 AM' },
  { key: '09:30', displayValue: '9:30 AM' },
  { key: '10:00', displayValue: '10:00 AM' },
  { key: '10:30', displayValue: '10:30 AM' },
  { key: '11:00', displayValue: '11:00 AM' },
  { key: '11:30', displayValue: '11:30 AM' },
  { key: '12:00', displayValue: '12:00 PM' },
  { key: '12:30', displayValue: '12:30 PM' },
  { key: '13:00', displayValue: '1:00 PM' },
  { key: '13:30', displayValue: '1:30 PM' },
  { key: '14:00', displayValue: '2:00 PM' },
  { key: '14:30', displayValue: '2:30 PM' },
  { key: '15:00', displayValue: '3:00 PM' },
  { key: '15:30', displayValue: '3:30 PM' },
  { key: '16:00', displayValue: '4:00 PM' },
  { key: '16:30', displayValue: '4:30 PM' },
  { key: '17:00', displayValue: '5:00 PM' },
  { key: '17:30', displayValue: '5:30 PM' },
  { key: '18:00', displayValue: '6:00 PM' },
  { key: '18:30', displayValue: '6:30 PM' },
  { key: '19:00', displayValue: '7:00 PM' },
  { key: '19:30', displayValue: '7:30 PM' },
  { key: '20:00', displayValue: '8:00 PM' },
  { key: '20:30', displayValue: '8:30 PM' },
  { key: '21:00', displayValue: '9:00 PM' },
  { key: '21:30', displayValue: '9:30 PM' },
  { key: '22:00', displayValue: '10:00 PM' },
  { key: '22:30', displayValue: '10:30 PM' },
  { key: '23:00', displayValue: '11:00 PM' },
  { key: '23:30', displayValue: '11:30 PM' },
];

const DeliverySchedule = ({ scheduleType, selectedTimes, onChange, onTimeChange }: CRMConfigProps): JSX.Element => {
  let timeSelector = <></>;
  if (scheduleType !== 'HOURLY') {
    timeSelector =
      scheduleType !== 'FOUR_TIMES_DAILY' ? (
        <Input
          value={selectedTimes.length > 0 ? selectedTimes[0] : undefined}
          type="select"
          name="deliveryScheduleType"
          id="deliveryScheduleType"
          onChange={(e) => onTimeChange([e.target.value])}
        >
          {timeOptions.map((option) => {
            return (
              <option key={option.key} value={option.key}>
                {option.displayValue}
              </option>
            );
          })}
        </Input>
      ) : (
        <MultiSelect options={timeOptions} maxSelections={4} selectedOptions={selectedTimes} setSelectedOptions={onTimeChange} />
      );
  }
  return (
    <>
      <Col sm="6">
        <FormGroup>
          <Label for="status">Delivery Schedule</Label>
          <Input value={scheduleType} type="select" name="deliveryScheduleType" id="deliveryScheduleType" onChange={onChange}>
            <option value="HOURLY">Hourly</option>
            <option value="DAILY">Daily at:</option>
            <option value="EVERY_TWO_HOURS">Every two hours starting at:</option>
            <option value="EVERY_FOUR_HOURS">Every four hours staring at:</option>
            <option value="FOUR_TIMES_DAILY">Up to four times daily</option>
          </Input>
        </FormGroup>
      </Col>
      {scheduleType !== 'HOURLY' && (
        <Col>
          <FormGroup>
            <Label for="status">{scheduleType === 'FOUR_TIMES_DAILY' ? 'Delivery Times' : 'Start Time'}</Label>
            {timeSelector}
          </FormGroup>
        </Col>
      )}
    </>
  );
};
export default DeliverySchedule;
