import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, Form, ModalBody, Row, Col, FormGroup, Label, Input, ModalFooter, Button, CustomInput } from 'reactstrap';
import { displayMergedPDF, downloadZipFile, downloadingDocuments } from '../../api/document';
import styles from '../../styles/archiveui.module.css';
import { Document } from '../../types/document';
import { useUserContext } from '../../contexts/UserContext';

interface DownloadModalProps {
  modal: boolean;
  toggle: () => void;
  documentsData: Document[];
  accountId: string;
  outPutDownloadProcess: (message: string, downloadDate: number) => void;
  onCloseModal: () => void;
}

const DownloadModal = ({ modal, toggle, documentsData, accountId, outPutDownloadProcess, onCloseModal }: DownloadModalProps): JSX.Element => {
  const { currentAccount } = useUserContext();
  const { user } = useUserContext().profile;
  const [allDocumentsEnable, setAllDocumentsEnable] = useState(true);
  const [oneFileEnable, setOneFileEnable] = useState(!allDocumentsEnable);
  const [selectedSortMethod, setSelectedSortMethod] = useState('studentLastName');
  const [errorMessage, setErrorMessage] = useState('');
  const [fileName, setFileName] = useState('');
  const [listOfFields, setListOfFields] = useState([
    {
      id: 'applicationId',
      name: 'Application ID',
    },
    {
      id: 'studentLastName',
      name: 'Student Last Name A-Z',
    },
    {
      id: 'dataSent',
      name: 'Data Sent',
    },
    {
      id: 'school',
      name: 'Originating School',
    },
  ]);
  const alertText =
    'You have selected more than five files. The files will be processed and available in the Queued Downloads. Modify the file name to display in Queued Downloads (optional).';
  const warningText = 'We need to select one or more files.';
  const zipFileDownloadConfirmationText = 'The Zip File was downloaded';
  const mergedPDFFileDownloadConfirmationText = 'The PDF File was downloaded.';
  const queueDownloadProcessConfirmationText = 'Your file was generated successfully and is available in the Queued Downloads.';
  const downloadFileErrorText = 'There is an error in the download process. Please, try again.';

  const sortList = () => {
    let sortedList: Document[] = [];
    if (selectedSortMethod == 'applicationId') {
      sortedList = documentsData.sort((a, b) => a.applicationId - b.applicationId);
    } else if (selectedSortMethod == 'studentLastName') {
      sortedList = documentsData.sort((a, b) => (a.metaData.lastname > b.metaData.lastname ? 1 : -1));
    } else if (selectedSortMethod == 'school') {
      sortedList = documentsData.sort((a, b) => (a.metaData.last_school_of_attendance > b.metaData.last_school_of_attendance ? 1 : -1));
    } else if (selectedSortMethod == 'dataSent') {
      sortedList = documentsData.sort((a, b) => (a.dataSent > b.dataSent ? 1 : a.dataSent < b.dataSent ? -1 : 0));
    }
    return sortedList;
  };

  const checkDifferentFromPDF = () => {
    const list = documentsData.filter((document: Document) => document.contentType != 'application/pdf');
    if (list.length > 0) setErrorMessage('We need to select just PDF files to merge in a PDF file');
    else setErrorMessage('');
    return list.length > 0 ? true : false;
  };

  const reLoadModal = () => {
    setFileName('');
    setSelectedSortMethod('studentLastName');
    setAllDocumentsEnable(true);
    setOneFileEnable(false);
  };

  useEffect(() => {
    if (modal) {
      reLoadModal();
    }
  }, [modal]);

  const downloadPDFFilesLikeOnePDF = async () => {
    try {
      if (!checkDifferentFromPDF()) {
        onCloseModal();
        const sortedList = sortList();
        const downloadDate = await displayMergedPDF(accountId, sortedList);
        if (downloadDate && downloadDate > 0) outPutDownloadProcess(mergedPDFFileDownloadConfirmationText, downloadDate);
      } else {
        setErrorMessage('Please to use this option just select PDF Files.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadPDFFilesLikeZipFile = async () => {
    try {
      onCloseModal();
      const downloadDate = await downloadZipFile(accountId, documentsData);
      if (downloadDate && downloadDate > 0) outPutDownloadProcess(zipFileDownloadConfirmationText, downloadDate);
    } catch (error) {
      console.log(error);
    }
  };

  const queueDownload = async () => {
    try {
      onCloseModal();
      const result = await downloadingDocuments(currentAccount.accountId, user.id, fileName, documentsData);
      if (result.fileName) {
        outPutDownloadProcess(queueDownloadProcessConfirmationText, result.created);
      } else {
        outPutDownloadProcess(downloadFileErrorText, 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadFiles = async () => {
    try {
      if (documentsData.length > 0) {
        if (documentsData.length >= 5) {
          await queueDownload();
        } else {
          if (oneFileEnable == true) {
            await downloadPDFFilesLikeZipFile();
          } else {
            await downloadPDFFilesLikeOnePDF();
          }
        }
      } else {
        setErrorMessage(warningText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal toggle={toggle} isOpen={modal} style={{ minWidth: '40em' }}>
        <ModalHeader toggle={toggle}>Download Documents</ModalHeader>
        <Form>
          <ModalBody>
            <Row form>
              <Col md={6}></Col>
              <Col md={6}>
                {errorMessage.length > 0 && (
                  <div>
                    <div className="w-15 p-2"></div>
                    <div className={styles.errorMessageStyle}>{errorMessage}</div>
                  </div>
                )}
              </Col>
            </Row>
            <Row form>
              <Col md={6}></Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Download Method:</Label>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}></Col>
              <Col md={6}>
                <CustomInput
                  checked={allDocumentsEnable}
                  onChange={() => {
                    if (allDocumentsEnable) {
                      setAllDocumentsEnable(false);
                      setOneFileEnable(true);
                    } else {
                      setAllDocumentsEnable(true);
                      setOneFileEnable(false);
                    }
                  }}
                  type="checkbox"
                  label="All documents in one file"
                  id="allDocumentsFilter"
                  name="allDocumentsFilter"
                />
              </Col>
            </Row>
            <Row form>
              <Col md={6}></Col>
              <Col md={6}>
                <CustomInput
                  checked={oneFileEnable}
                  onChange={() => {
                    if (oneFileEnable) {
                      setOneFileEnable(false);
                      setAllDocumentsEnable(true);
                    } else {
                      setOneFileEnable(true);
                      setAllDocumentsEnable(false);
                    }
                  }}
                  type="checkbox"
                  label="One document per file"
                  id="oneDocumentFilter"
                  name="oneDocumentFilter"
                />
              </Col>
            </Row>
            <div className="w-15 p-2"></div>
            {allDocumentsEnable && (
              <Row form>
                <Col md={4}></Col>
                <Col md={2}>
                  <div>Sort Order:</div>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Input
                      type="select"
                      id="cboSort"
                      name="cboSort"
                      value={selectedSortMethod}
                      onChange={(e) => {
                        setSelectedSortMethod(e.target.value);
                      }}
                    >
                      {listOfFields.map((field) => {
                        return (
                          <option key={field.id} value={field.id}>
                            {field.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            )}
            {documentsData.length >= 5 && (
              <Row>
                <Col md={6}>
                  <div>{alertText}</div>
                </Col>
                <Col md={6}>
                  <Input type="text" placeholder="downloaded-files" value={fileName} onChange={(e) => setFileName(e.target.value)} />
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button className={styles.scrbBtnBlue} onClick={toggle}>
              Cancel
            </Button>{' '}
            <Button className={styles.scrbBtnOrange} type="button" onClick={() => downloadFiles()}>
              Download
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default DownloadModal;
