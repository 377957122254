import React, { FormEvent, useState } from 'react';
import { Alert, Button, CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { User } from '../../types/user';
import { useUserContext } from '../../contexts/UserContext';
import { updateUserProfile } from '../../api/userprofile';
import { toast } from 'react-toastify';
import { Account } from '../../types/account';
import { validatePhoneNumber } from '../../utils/phone';
import { PermissionActions } from '../../types/role';

export interface UserPermission {
  uri: string;
  actions: PermissionActions;
}

export interface UserProfile {
  user: User;
  associatedAccounts: Account[];
  permissions: Record<string, Record<string, UserPermission>>; // structure is { "${accountId}": {"${permissionId}": {create: true, update: true, read: false, delete: true}}}
  documentTypePermissions: Record<string, Record<string, PermissionStatus>>;
}

const UserProfilePage = () => {
  const [phoneError, setPhoneError] = useState<string>();
  const {
    profile: { user },
    setUpdatedProfile,
  } = useUserContext();
  const [userProfile, setUserProfile] = useState({
    id: user.id,
    familyName: user.familyName,
    givenName: user.givenName,
    email: user.email,
    phone: user.phone,
  });

  const validatePhone = (phoneNumber: string) => {
    if (validatePhoneNumber(phoneNumber)) {
      setPhoneError(undefined);
      return true;
    } else {
      setPhoneError('Invalid Phone Number');
      return false;
    }
  };

  const addToPartialUserProfile = (formattedValue: string, event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target.name != undefined) {
      setUserProfile((prevValues) => {
        return { ...prevValues, [event.target.name]: event.target.value };
      });
      setUserProfile((prevValues) => {
        return { ...prevValues, [event.target.name]: event.target.value };
      });
    } else if (formattedValue != '') {
      setUserProfile((prevValues) => {
        return { ...prevValues, ['phone']: formattedValue };
      });
      setUserProfile((prevValues) => {
        return { ...prevValues, ['phone']: formattedValue };
      });
    }
  };

  const updateProfileHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validatePhone(userProfile.phone)) {
      return;
    }
    const response = await toast.promise(updateUserProfile(userProfile), {
      pending: 'Updating Profile',
      success: 'Profile Updated',
      error: 'There was an error',
    });
    if (response === 200) {
      setUpdatedProfile({ ...user, familyName: userProfile.familyName, givenName: userProfile.givenName, phone: userProfile.phone });
      return response;
    }
    return response;
  };

  return (
    <div className={styles.detailContainer}>
      <CardBody className={styles.searchBarHeader}>
        <h5 className={styles.searchBarTitle}>User Profile</h5>
      </CardBody>
      <CardBody>
        <Form
          onSubmit={(event) => {
            updateProfileHandler(event);
          }}
        >
          <Row form>
            <Col>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input name="givenName" value={userProfile.givenName} onChange={(e) => addToPartialUserProfile('', e)} required />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="familyName">Last Name</Label>
                <Input name="familyName" value={userProfile.familyName} onChange={(e) => addToPartialUserProfile('', e)} required />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input name="email" value={userProfile.email} disabled={true} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="phone">Phone</Label>
                <PhoneInput
                  value={userProfile.phone}
                  inputStyle={{ width: '100%' }}
                  country={'us'}
                  onlyCountries={['us']}
                  disableDropdown={true}
                  onChange={(formattedValue) => addToPartialUserProfile(formattedValue)}
                  isValid={!phoneError}
                  onBlur={(event) => validatePhone(event.target.value)}
                />
              </FormGroup>
              {phoneError && (
                <Alert color="danger" style={{ textAlign: 'center' }}>
                  {phoneError}
                </Alert>
              )}
            </Col>
          </Row>
          <div className={styles.detailBtnContainer}>
            <Button className={styles.scrbBtnOrange}>Save</Button>
          </div>
        </Form>
      </CardBody>
    </div>
  );
};

export default UserProfilePage;
