import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { MdClear } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';
import { CardBody, Form, Row, Col, FormGroup, Input, Button, UncontrolledTooltip } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import { Link } from 'react-router-dom';

interface searchBarProps {
  updateSearchText: Dispatch<SetStateAction<string>>;
  updateStatusValue: Dispatch<SetStateAction<string>>;
  searchText: string;
  status: string;
  exportUserData: () => void;
  exportAccounts: () => void;
  setColleges: () => void;
}

const SearchBar = ({ updateSearchText, updateStatusValue, searchText, status, exportUserData, exportAccounts, setColleges }: searchBarProps): JSX.Element => {
  const handleActiveInactive = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    updateStatusValue(val);
  };

  const clearFilters = () => {
    updateSearchText('');
    updateStatusValue('basic');
  };

  return (
    <div>
      <CardBody className={styles.searchBarHeader}>
        <h5 className={styles.searchBarTitle}>Accounts</h5>
      </CardBody>
      <CardBody className={styles.searchBarBody}>
        <Form>
          <Row form className={styles.rowContainer}>
            <Col md="4">
              <FormGroup>
                <Input
                  type="text"
                  id="search"
                  placeholder="Search Name, Short Name"
                  value={searchText}
                  onChange={(e) => {
                    updateSearchText(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup>
                <Input type="select" name="status" id="status" placeholder="Status" value={status} onChange={handleActiveInactive}>
                  <option value="basic">Starter</option>
                  <option value="premium">Essentials</option>
                  <option value="inactive">Inactive</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="2">
              <Button id="clearFilterBtn" className={styles.filterBtn} onClick={clearFilters}>
                <MdClear />
              </Button>
              <UncontrolledTooltip target="clearFilterBtn">Clear Search</UncontrolledTooltip>
              <Button
                id="addAccountBtn"
                color="#f68b2b"
                style={{ backgroundColor: '#f68b2b', color: 'white' }}
                tag={Link}
                to="/accountdetail"
                onClick={clearFilters}
              >
                <BsPlus />
              </Button>
              <UncontrolledTooltip target="addAccountBtn">Add Account</UncontrolledTooltip>
            </Col>
            <Col md="1.5">
              <Button id="exportUsersBtn" className={styles.scrbBtnBlue} onClick={exportUserData}>
                Export Users
              </Button>
              <UncontrolledTooltip target="exportUsersBtn">Export Users</UncontrolledTooltip>
            </Col>
            <Col md="1.5">
              <Button id="exportAccountsBtn" className={styles.scrbBtnGreen} onClick={exportAccounts}>
                Export Accounts
              </Button>
            </Col>
            <Col md="1">
              <Button id="setCollegesBtn" onClick={setColleges}>
                Sync Up
              </Button>
              <UncontrolledTooltip target="setCollegesBtn">Sync Up Colleges</UncontrolledTooltip>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </div>
  );
};

export default SearchBar;
