import * as React from 'react';
import { useUserContext } from '../contexts/UserContext';
import { FcLock } from 'react-icons/fc';
import { UncontrolledTooltip } from 'reactstrap';
import { useRef } from 'react';

interface PremiumAccessControlProps {
  children: React.ReactNode;
  highlight: boolean;
  removeIcon?: boolean;
  styling?: React.CSSProperties;
}
export const PremiumAccessControl = ({ children, highlight, styling, removeIcon }: PremiumAccessControlProps) => {
  const { currentAccount, profile } = useUserContext();
  const tooltipRef = useRef(null);
  let style: React.CSSProperties = {
    opacity: 0.5,
    pointerEvents: 'none',
  };
  if (styling) {
    style = {
      ...style,
      ...styling,
    };
  }
  return currentAccount.status === 'premium' || profile.user.isScribblesAdmin ? (
    <>{children}</>
  ) : highlight ? (
    <span style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
      <fieldset disabled={true} style={style}>
        {children}
      </fieldset>
      <span ref={tooltipRef} style={{ marginLeft: '0.3rem' }}>
        <FcLock size={25} style={{ display: removeIcon ? 'none' : 'block' }} />
      </span>
      <UncontrolledTooltip container={tooltipRef} target={tooltipRef}>
        This is a feature of the Premium version. Contact your administrator.
      </UncontrolledTooltip>
    </span>
  ) : (
    <></>
  );
};
