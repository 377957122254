import fetch from 'node-fetch';
import { Status, User, UserPartial } from '../types/user';
import { APIUser } from '../types/user';
import { getAuthToken, getBaseApiUrl } from './helpers';

export const getUsers = async (): Promise<User[]> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/user`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<User[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getAdminUsers = async (): Promise<User[]> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/user/_admin`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<User[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getUsersForAccount = async (accountId: string): Promise<User[]> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/${accountId}/user`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<User[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

// url: '/accountUserTestID/user/addaccountUserId'
export const getUser = async (id: string, accountId?: string): Promise<User> => {
  const url = `${getBaseApiUrl()}/v1/api/forward${accountId ? `/${accountId}` : ''}/user/${id}`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<User>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getUserByCognitoId = async (cognitoId: string): Promise<User> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/user/${cognitoId}`;
  const response = await fetch(url);
  if (response.ok) {
    return response.json() as Promise<User>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const updateUser = async (newUser: boolean, userData: UserPartial, accountId: string, id: string): Promise<number> => {
  const method = newUser ? 'PUT' : 'PATCH';
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(userData),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/user/${id}`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const resendTemporaryPassword = async (accountId: string, userId: string) => {
  const method = 'PATCH';
  const requestOptions = {
    method: method,
    headers: { Authorization: await getAuthToken() },
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/user/${userId}/password`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const updateUserAdmin = async (userData: UserPartial): Promise<number> => {
  const method = 'PATCH';
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(userData),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/user`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const updateAccountMembershipStatus = async (accountId: string, id: string, status: Status) => {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ status }),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/user/${id}/membership`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const setUsersForRole = async (accountId: string, roleId: string, userIds: string[]): Promise<number> => {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(userIds),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/role/${roleId}/users`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const removeUserForRole = async (accountId: string, roleId: string, userId: string): Promise<number> => {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/role/${roleId}/users/${userId}`, requestOptions);
  if (response.ok) {
    return response.status;
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const createUsersForAccount = async (accountId: string, users: Partial<User>[] | Partial<User>, roles?: string[]): Promise<number> => {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ users, roles }),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/user`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getUsersForRole = async (accountId: string, roleId: string): Promise<User[]> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/${accountId}/role/${roleId}/users`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<User[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const updateUserRoles = async (accountId: string, userId: string, roles: string[]) => {
  const url = `${getBaseApiUrl()}/v1/api/forward/${accountId}/user/${userId}/role`;
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(roles),
  };

  const response = await fetch(url, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const inviteScribblesAdmin = async (email: string) => {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ email: email, isScribblesAdmin: true }),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/user`, requestOptions);
  if (response.ok) {
    return response.text();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else if (response.status === 405) {
    return Promise.reject('User already exists');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getAPIUser = async (id: string): Promise<APIUser> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/user/_apiuser/${id}`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<APIUser>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getAPIUsersForAccount = async (accountId: string): Promise<APIUser[]> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/user/_account/_apiuser/${accountId}`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<APIUser[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const updateAPIUser = async (newUser: boolean, userData: Partial<APIUser>): Promise<number> => {
  const method = newUser ? 'PUT' : 'PATCH';
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(userData),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/user/_apiuser`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const inviteExternalUser = async (accountId: string, emailAddress: string, documentIds: string[], expirationDate: Date) => {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({
      account: accountId,
      email: emailAddress,
      documentIds: JSON.stringify(documentIds),
      expires: expirationDate.toISOString(),
    }),
  };

  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/externaluser`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};
