import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { useUserContext } from '../contexts/UserContext';
import { useHistory } from 'react-router-dom';
import { LoadingComponent } from '../Loading';
import styles from '../styles/archiveui.module.css';
import { Button, ButtonGroup, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { CRMIntegrationConfig } from '../types/crmintegration/config';
import { getCRMConfig, sendFile, testSftpConnection, updateCRMConfig } from '../api/crmintegration';
import AccessControl from '../users/userprofile/accesscontrol';
import DeliverySchedule from './deliveryschedule';
import { toast } from 'react-toastify';

const CRMConfig = (): JSX.Element => {
  const { currentAccount } = useUserContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [config, setConfig] = useState<CRMIntegrationConfig>({
    accountId: '',
    sftpKey: '',
    username: '',
    activeStatus: false,
    sftpPath: '',
    sftpUrl: '',
    deliveryScheduleType: 'HOURLY',
    times: [],
  });

  const fetchCurrentConfig = async (accountId: string) => {
    const currConfig = await getCRMConfig(accountId);
    if (currConfig) {
      setConfig(currConfig);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCurrentConfig(currentAccount.accountId);
  }, [currentAccount]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const updatedValue = name === 'activeStatus' ? value === 'true' : value;
    setConfig((currConfig) => {
      return { ...currConfig, [name]: updatedValue };
    });
  };

  const utcToLocalTime = (utcTime: string): string => {
    const [hours, minutes] = utcTime.split(':').map(Number);
    const utcDate = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), hours, minutes));
    const localDate = new Date(utcDate.toLocaleString());
    const dayDifference = localDate.getDate() - utcDate.getUTCDate();
    let adjustedDate = localDate;
    if (dayDifference !== 0) {
      adjustedDate = new Date(localDate.setDate(localDate.getDate() + dayDifference));
    }
    return adjustedDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  const localToUTCTime = (localTime: string): string => {
    const [hours, minutes] = localTime.split(':').map(Number);
    const localDate = new Date();
    localDate.setHours(hours, minutes, 0, 0);
    const utcDate = new Date(localDate.toISOString());
    const dayDifference = utcDate.getUTCDate() - localDate.getDate();
    let adjustedDate = utcDate;
    if (dayDifference !== 0) {
      adjustedDate = new Date(utcDate.setUTCDate(utcDate.getUTCDate() - dayDifference));
    }
    const utcHours = adjustedDate.getUTCHours().toString().padStart(2, '0');
    const utcMinutes = adjustedDate.getUTCMinutes().toString().padStart(2, '0');
    return `${utcHours}:${utcMinutes}`;
  };

  const onTimeChange = (selectedTimes: string[]) => {
    const utcTimes = selectedTimes.map((time) => {
      return localToUTCTime(time);
    });
    setConfig((currConfig) => ({ ...currConfig, times: utcTimes }));
  };

  const _saveConfigHandler = async () => {
    const response = await toast.promise(updateCRMConfig(currentAccount.accountId, config), {
      pending: 'Updating configuration',
      success: 'Configuration updated',
      error: 'Unable to update configuration, please try again.',
    });
    return response;
  };

  const testSftpConnectionInternal = async () => {
    await toast.promise(testSftpConnection(currentAccount.accountId, config.sftpUrl, config.username, config.sftpKey), {
      pending: 'Testing connection...',
      error: 'Connection failed...',
      success: 'Connection successful',
    });
  };

  const sendFileInternal = async () => {
    await toast.promise(sendFile(currentAccount.accountId), {
      pending: 'Sending file...',
      error: 'Unable to send file...',
      success: 'File Sent',
    });
  };

  const saveConfigHandler = async () => {
    setIsSubmitting(true);
    try {
      await _saveConfigHandler();
    } catch (e) {
      toast.error('Unable to update configuration, please try again.');
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    saveConfigHandler();
  };

  let requiredFlag = {};
  if (config.activeStatus) {
    requiredFlag = { required: true };
  }
  return (
    <LoadingComponent isLoading={loading}>
      <div>
        <div className={styles.detailContainer}>
          <CardBody className={styles.searchBarHeader}>
            <h5 style={{ textAlign: 'start', fontWeight: 500, marginTop: '.5em' }}>
              CRM Integration: {`${currentAccount.crmStatus?.charAt(0).toUpperCase()}${currentAccount.crmStatus?.slice(1).toLowerCase()}`}
            </h5>
          </CardBody>
          <CardBody>
            <Form
              onSubmit={(event) => {
                submit(event);
              }}
            >
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="status">Integration Status</Label>
                    <Input value={`${config.activeStatus}`} type="select" name="activeStatus" id="activeStatus" onChange={onChange}>
                      <option value="false">False</option>
                      <option value="true">True</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="status">SFTP URL</Label>
                    <Input value={config.sftpUrl} type="text" name="sftpUrl" id="sftpUrl" onChange={onChange} {...requiredFlag}></Input>{' '}
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="status">SFTP Path</Label>
                    <Input value={config.sftpPath} type="text" name="sftpPath" id="sftpPath" onChange={onChange} {...requiredFlag}></Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="status">SFTP Key</Label>
                    <Input value={config.sftpKey} type="text" name="sftpKey" id="sftpKey" onChange={onChange} {...requiredFlag}></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="status">SFTP Username</Label>
                    <Input value={config.username} type="text" name="username" id="username" onChange={onChange} {...requiredFlag}></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <DeliverySchedule
                  scheduleType={config.deliveryScheduleType}
                  selectedTimes={config.times.map((time) => utcToLocalTime(time))}
                  onTimeChange={onTimeChange}
                  onChange={onChange}
                />
              </Row>
              <div className={styles.detailBtnContainer}>
                <AccessControl permissionId={'crmIntegration'} action={'update'}>
                  <ButtonGroup>
                    <Button disabled={isSubmitting} className={styles.scrbBtnOrange}>
                      Save
                    </Button>
                    <Button
                      disabled={!config.sftpKey || !config.sftpUrl || !config.username || !config.sftpPath}
                      className={styles.scrbBtnGreen}
                      onClick={sendFileInternal}
                    >
                      Send Now
                    </Button>
                    <Button
                      disabled={!config.sftpKey || !config.sftpUrl || !config.username}
                      className={styles.scrbBtnBlue}
                      onClick={testSftpConnectionInternal}
                    >
                      Test Connection
                    </Button>
                    <Button disabled={true} className={styles.scrbBtnRed}>
                      Cancel
                    </Button>
                  </ButtonGroup>
                </AccessControl>
              </div>
            </Form>
          </CardBody>
        </div>
      </div>
    </LoadingComponent>
  );
};

export default CRMConfig;
