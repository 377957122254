import React, { useEffect, useState } from 'react';
import AuthService from '../auth/authservice';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import logo from '../main/scribforward_blue.png';
import scribblesLogo from './bird-icon-right-logo.png';
import PasswordChecklist from 'react-password-checklist'

const Login = () => {
  const [authService, setAuthService] = useState<AuthService>();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userAttrs, setUserAttrs] = useState({});
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);

  useEffect(() => {
    console.log('login auth service useeffect');
    const getAuthService = async () => {
      const service = await AuthService.getInstance();
      setAuthService(service);
      if (service.isAuthenticated()) {
        service.signinRedirect();
      }
    };
    getAuthService();
  }, []);

  const handleUserAttributes = (userAttrs: any) => {
    if (userAttrs) {
      setIsLoading(false);
      setErrorMessage('');
      setNewPasswordRequired(true);
      setUserAttrs(userAttrs);
    }
  }

  const handleErrorMessage = (errorMessage: string) => {
    setIsLoading(false);
    setErrorMessage(errorMessage);
  };

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    if (authService) {
      await authService.login(email, password, handleErrorMessage, handleUserAttributes);
    }
  };

  const handleNewPassword = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    if (authService) {
      await authService.completeNewPasswordChallenge(newPassword, userAttrs, handleErrorMessage);
    }
  }

  const Loader = ({ className }: any) => (
    <div className={className}>
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.38798 12.616C3.36313 12.2306 2.46328 11.5721 1.78592 10.7118C1.10856 9.85153 0.679515 8.82231   0.545268 7.73564C0.411022 6.64897 0.576691 5.54628 1.02433 4.54704C1.47197 3.54779 2.1845 2.69009 3.08475   2.06684C3.98499 1.4436 5.03862 1.07858 6.13148 1.01133C7.22435 0.944078 8.31478 1.17716 9.28464    1.68533C10.2545 2.19349 11.0668 2.95736 11.6336 3.89419C12.2004 4.83101 12.5 5.90507 12.5 7"
          stroke="white"
        />
      </svg>
    </div>
  );

  const CustomButton = ({ onSubmit, text, loading = false, disabled }: any) => {
    return (
      <Button color="success" size="lg" onClick={onSubmit} disabled={disabled}>
        {text}
      </Button>
    )
  }

  const LoginForm = (
    <Form>
      <FormGroup>
        <Input type="email" id="email" value={email} placeholder="Email" onChange={(event) => setEmail(event.target.value)} />
      </FormGroup>
      <FormGroup>
        <Input type="password" id="password" value={password} placeholder="Password" onChange={(event) => setPassword(event.target.value)} />
      </FormGroup>
      <FormGroup>
        <CustomButton
          text="Sign in"
          onSubmit={onSubmit}
          loading={isLoading}
          disabled={isLoading}
        />
        <Link to="/forgotPassword" style={{ float: 'right'}}>Forgot your password?</Link>
      </FormGroup>
    </Form>
  );

  const NewPassword = (
    <Form>
      <FormGroup>
        <Input type="password" id="password" value={newPassword} placeholder="New Password" onChange={(event) => setNewPassword(event.target.value)} />
      </FormGroup>
      <FormGroup>
        <Input type="password" id="passwordAgain" value={newPasswordAgain} placeholder="Confirm Password" onChange={(event) => setNewPasswordAgain(event.target.value)} />
      </FormGroup>
      <FormGroup>
        <PasswordChecklist
          rules={["minLength","number","lowercase","capital","match"]}
          minLength={8}
          value={newPassword}
          valueAgain={newPasswordAgain}
          onChange={(isValid) => setIsNewPasswordValid(isValid)}
        />
      </FormGroup>
      <FormGroup>
        <CustomButton
          text="Confirm"
          onSubmit={handleNewPassword}
          loading={isLoading}
          disabled={!isNewPasswordValid}
        />
      </FormGroup>
    </Form>
  );

  return (
    <div>
    <Container style={{ paddingTop: '5em' }}>
      <Row>
        <Col sm="12" md={{ size: 4, offset: 4 }}>
          <img src={logo} style={{ height: '120px', top: 0, left: 0, display:  'flex' }} alt="" />
        </Col>
      </Row>
      <Row style={{ marginTop: '3em' }}>
        <Col sm="12" md={{ size: 4, offset: 4 }}>
          {errorMessage ? <Alert color="danger">{errorMessage}</Alert> : ''}
        </Col>
      </Row>
      <Row>
        <Col sm="12" md={{ size: 4, offset: 4 }}>
          {newPasswordRequired ? NewPassword : LoginForm}
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="3">
        </Col>
        <Col sm="12" md={{ size: 6, offset: 3 }}>
          <img src={scribblesLogo} width="150px" className="pull-right"  alt="Scribbles logo"/>
        </Col>
        <Col sm="12" md="3">
        </Col>
      </Row>
      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <p style={{ textAlign: 'center', marginTop: '2em' }}>Interested in ScribForward for your institution? Visit <a href="https://www.scribsoft.com/scribforward" target="_blank">www.scribsoft.com/scribforward</a> to learn more about higher education applicant document management solutions.</p>
        </Col>
      </Row>
    </Container>
    <footer>
      <p>
        <a href="https://scribsoft.com/customer-responsibilities" target="_blank">Terms of Use</a>
        <a href="https://scribsoft.com/privacy-policy" target="_blank" style={{ marginLeft: '3em' }}>Privacy Policy</a>
      </p>
    </footer>
  </div>
  );
};

export default withRouter(Login);
