import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toastError } from '../../toast';
import { useUserContext } from '../../contexts/UserContext';
import { getQueuedDownloadContent, getUserDownloads, searchDocuments } from '../../api/document';
import DocumentList from '../documentlist';
import { Document, QueuedDownload } from '../../types/document';
import SearchComponent from './searchcomponent';
import { Button, Col, Row, Badge } from 'reactstrap';
import { useIntercom } from 'react-use-intercom';
import { QueuedDownloads } from '../queueddownloads';
import styles from '../../styles/archiveui.module.css';
import { PremiumAccessControl } from '../../componentutils/PremiumAccessControl';

type SearchState = {
  query: string;
  pageSize: number;
  currentPage?: number;
  documents?: Document[];
  searching: boolean;
  error?: string;
  totalCount: number;
  sort?: any;
};

const defaultSort: any = [{ id: 'created', desc: true }];
type Action =
  | { type: 'submit_search'; query: string }
  | { type: 'reset_search' }
  | { type: 'update_page'; page: number }
  | { type: 'update_page_size'; pageSize: number }
  | { type: 'update_documents'; documents: Document[]; count: number }
  | { type: 'search_error'; message: string }
  | { type: 'update_sort'; sort: any }
  | { type: 'sticky_search'; prevState: SearchState };

const searchReducer = (state: SearchState, action: Action): SearchState => {
  switch (action.type) {
    case 'reset_search': {
      return {
        query: '*',
        currentPage: 1,
        searching: true,
        totalCount: 0,
        sort: defaultSort,
        pageSize: 10,
      };
    }
    case 'update_page': {
      return {
        ...state,
        currentPage: action.page,
        searching: true,
      };
    }
    case 'update_page_size': {
      return {
        ...state,
        pageSize: action.pageSize,
        searching: true,
      };
    }
    case 'update_sort': {
      return {
        ...state,
        currentPage: 1,
        sort: action.sort,
        searching: true,
      };
    }
    case 'update_documents': {
      return {
        ...state,
        documents: action.documents,
        totalCount: action.count,
        searching: false,
        error: '',
      };
    }
    case 'submit_search': {
      return {
        ...state,
        query: action.query,
        currentPage: 1,
        searching: true,
        totalCount: 0,
      };
    }
    case 'search_error': {
      return {
        ...state,
        error: action.message,
        searching: false,
        documents: [],
        totalCount: 0,
      };
    }
    case 'sticky_search': {
      return { ...action.prevState, searching: true, pageSize: 10, sort: defaultSort };
    }
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

const DocumentSearch = () => {
  const { currentAccount } = useUserContext();
  const { user } = useUserContext().profile;
  const { update } = useIntercom();
  update({
    userId: user.id,
    name: `${user.givenName} ${user.familyName}`,
    email: user.email,
    company: { companyId: currentAccount.accountId, name: currentAccount.name },
  });
  const [searchState, dispatch] = React.useReducer(searchReducer, {
    searching: false,
    query: '*',
    documents: undefined,
    currentPage: undefined,
    error: undefined,
    totalCount: 0,
    sort: defaultSort,
    pageSize: 10,
  });
  const { query, currentPage, documents, searching, error, totalCount, sort, pageSize } = searchState;
  const searchStorageKey = `scribforward_documentsearch_${user.id}_${currentAccount.accountId}`;
  const [showQueuedDownloads, setShowQueuedDownloads] = useState(false);
  const [queuedDownloads, setQueuedDownloads] = useState<QueuedDownload[]>([]);
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (searching) searchDocumentsInternal();
  }, [searching]);

  useEffect(() => {
    const { query, currentPage, sort, documents, pageSize } = searchState;
    if (query && documents && documents.length > 0) {
      localStorage.setItem(searchStorageKey, JSON.stringify({ query, currentPage, sort, pageSize }));
    }
  }, [searchState]);

  useEffect(() => {
    dispatch({ type: 'submit_search', query });
    const storageItem = localStorage.getItem(searchStorageKey);
    if (!storageItem) return;
    const prevState: SearchState = JSON.parse(storageItem);
    if (prevState.query) {
      setIsFiltered(prevState.query != '*');
      dispatch({ type: 'sticky_search', prevState });
    }
  }, []);

  const sortString = useMemo(() => {
    if (!sort) return;
    return sort;
  }, [sort]);

  const searchDocumentsInternal = async () => {
    const searchResults = await toastError(searchDocuments(currentAccount.accountId, query, currentPage, pageSize, sortString), 'Error searching documents');
    if (searchResults.count === 0) {
      dispatch({ type: 'search_error', message: 'No document results found.' });
      return;
    }
    dispatch({ type: 'update_documents', documents: searchResults.documents, count: searchResults.count });
  };

  const resetSearch = useCallback(() => {
    dispatch({ type: 'reset_search' });
    setIsFiltered(false);
    localStorage.removeItem(searchStorageKey);
  }, []);

  const submitSearch = useCallback((query: string) => {
    setIsFiltered(query != '*');
    dispatch({ type: 'submit_search', query });
  }, []);

  const updatePage = useCallback((newPage: number) => {
    dispatch({ type: 'update_page', page: newPage });
  }, []);

  const updatePageSize = useCallback((pageSize: number) => {
    dispatch({ type: 'update_page_size', pageSize: pageSize });
  }, []);

  const updateSort = useCallback((newSort: any) => {
    dispatch({ type: 'update_sort', sort: newSort });
  }, []);

  const getQueuedDownloads = async (showDownloaded: boolean) => {
    const userDownloads = await getUserDownloads(currentAccount.accountId, user.id);
    if (!showDownloaded) {
      const filteredDownloads = userDownloads.filter((download) => download.downloaded == undefined);
      setQueuedDownloads(filteredDownloads);
      return;
    }
    setQueuedDownloads(userDownloads);
  };

  const handleQueuedDownload = async (downloadId: string) => {
    const content = await toastError(getQueuedDownloadContent(currentAccount.accountId, user.id, downloadId), 'Error loading pdf document');
    if (!content) {
      return;
    }

    const blob = new Blob([content], { type: 'application/zip' });
    const url = URL.createObjectURL(blob);
    const newWin = window.open(url);

    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
      //POPUP BLOCKED
      console.log('POPUP BLOCKED');
    }
  };

  return (
    <>
      <Row style={{ marginBottom: '.5em' }}>
        <Col>
          <h3>Inbox - Student Files Received {isFiltered && <Badge color="info">Filtered</Badge>}</h3>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
          <PremiumAccessControl highlight={true}>
            <Button
              onClick={() => setShowQueuedDownloads(!showQueuedDownloads)}
              className={showQueuedDownloads ? '' : styles.scrbBtnOrange}
              style={{ backgroundColor: showQueuedDownloads ? 'gray' : '#f68b2b', float: 'right' }}
              disabled={searching}
            >
              Queue Downloads
            </Button>
          </PremiumAccessControl>
        </Col>
      </Row>
      {showQueuedDownloads && <QueuedDownloads data={queuedDownloads} fetchData={getQueuedDownloads} handleQueuedDownload={handleQueuedDownload} />}
      <Row style={{ marginBottom: '.5em' }}>
        <Col>
          <SearchComponent submitSearch={submitSearch} searchError={error} searching={searching} startNewSearch={resetSearch} />
        </Col>
      </Row>
      <DocumentList
        loadingDocuments={searching}
        totalCount={totalCount}
        updatePage={updatePage}
        pageSize={pageSize}
        documents={documents}
        currentPage={currentPage}
        currentSort={sort}
        updateSort={updateSort}
        updatePageSize={updatePageSize}
        refreshDocuments={resetSearch}
      />
    </>
  );
};

export default DocumentSearch;
