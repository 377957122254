import React, { ChangeEvent, KeyboardEvent } from 'react';
import { ListGroupItem, Input, CustomInput, Row, Col, Button, Alert } from 'reactstrap';
import { BsFillTrashFill } from 'react-icons/bs';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import styles from '../../styles/archiveui.module.css';

interface AccountContactProps {
  name: string;
  email: string;
  role: string;
  phone: string;
  indexOfContact: number;
  isPrimary: boolean;
  newAccount: boolean;
  updateFunction: (targetName: string, value: string, index: number) => void;
  updatePrimaryContact: (isPrimary: boolean, index: number) => void;
  removeContactHandler(index: number): any;
  error?: string;
}

const AccountContact: React.FC<AccountContactProps> = (props: AccountContactProps) => {
  return (
    <ListGroupItem className={styles.accountContactContainer}>
      <Row form>
        <Col>
          <Input
            name="name"
            value={props.name}
            placeholder="Contact Name"
            onChange={(e) => {
              props.updateFunction('name', e.target.value, props.indexOfContact);
            }}
            required
          />
        </Col>
        <Col>
          <Input
            name="email"
            value={props.email}
            placeholder="Contact Email"
            onChange={(e) => {
              props.updateFunction('email', e.target.value, props.indexOfContact);
            }}
            required
          />
        </Col>
        <Col>
          <PhoneInput
            country="us"
            preferredCountries={['us']}
            value={props.phone}
            placeholder="Contact Phone"
            inputStyle={{ width: '100%' }}
            onChange={(value) => props.updateFunction('phone', value, props.indexOfContact)}
          />
        </Col>
        <Col md={2}>
          <CustomInput
            label="Primary Contant"
            name="isPrimary"
            type="checkbox"
            id={`isPrimary${props.indexOfContact}`}
            checked={props.isPrimary}
            onChange={(e) => {
              props.updatePrimaryContact(e.target.checked, props.indexOfContact);
            }}
          />
        </Col>
        <Button
          color="danger"
          style={{ marginRight: '6px' }}
          onClick={() => {
            props.removeContactHandler(props.indexOfContact);
          }}
        >
          <BsFillTrashFill />
        </Button>
      </Row>
      {props.error && (
        <Alert color="danger" style={{ textAlign: 'center', marginTop: '10px', marginBottom: '0px' }}>
          {props.error}
        </Alert>
      )}
    </ListGroupItem>
  );
};

export default AccountContact;
