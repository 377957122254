import fetch from 'node-fetch';
import { Role, OptionalRole, Permission } from '../types/role';
import { getAuthToken, getBaseApiUrl } from './helpers';

export const getRoles = async (accountId: string): Promise<Role[]> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/${accountId}/role`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<Role[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

// url: '/roleTestaccount/role/testRoleId'
export const getRole = async (accountId: string, id: string): Promise<Role> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/${accountId}/role/${id}`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<Role>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

// url: '/roleTestaccount/role'
export const updateRole = async (newRole: boolean, roleData: OptionalRole, accountId: string): Promise<number> => {
  const method = newRole ? 'PUT' : 'PATCH';
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(roleData),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/role`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getPermissions = async (): Promise<Record<string, Permission>> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/permissions`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<Record<string, Permission>>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const setUsersForRole = async (accountId: string, roleId: string, userIds: string[]): Promise<number> => {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(userIds),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/role/${roleId}/users`, requestOptions);
  if (response.ok) {
    return response.json() as Promise<number>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const removeUserForRole = async (accountId: string, roleId: string, userId: string): Promise<number> => {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/role/${roleId}/users/${userId}`, requestOptions);
  if (response.ok) {
    return response.json() as Promise<number>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};
