import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import moment from 'moment';
import { useTable, useSortBy, usePagination, SortingRule } from 'react-table';
import { Table, Card, Input, Button, Alert, Row, Col } from 'reactstrap';
import styles from '../styles/archiveui.module.css';
import { AuditEntry, AuditFilterEntry } from '../types/audit';
import AuditLogSearchBar from './auditlogSearchBar';

interface AuditLogProps {
  audits: AuditEntry[];
  filters: AuditFilterEntry;
  updateUserFilter: Dispatch<SetStateAction<string>>;
  updateActionFilter: Dispatch<SetStateAction<string>>;
  updateStartDate: Dispatch<SetStateAction<string>>;
  updateEndDate: Dispatch<SetStateAction<string>>;
  userFilter: string;
  actionFilter: string;
  startDate: string;
  endDate: string;
  totalCount: number;
  selectedPageSize: number;
  totalPages: number;
  updatePageSize: (size: number) => void;
  updatePage?: (page: number) => void;
  controlledPage?: number;
  currentSort?: any;
  updateSort?: (newSort: any) => void;
  exportAuditData: () => void;
  filterAuditData: (reset: boolean) => void;
}

const AuditLogTable = ({
  audits,
  filters,
  updateUserFilter,
  updateActionFilter,
  updateStartDate,
  updateEndDate,
  userFilter,
  actionFilter,
  startDate,
  endDate,
  exportAuditData,
  filterAuditData,
  totalCount,
  selectedPageSize,
  totalPages,
  updatePage,
  updatePageSize,
  controlledPage,
  updateSort,
}: AuditLogProps): JSX.Element => {
  const initialSort: SortingRule<AuditEntry>[] = React.useMemo(
    () => [
      {
        id: 'time',
        desc: true,
      },
    ],
    [],
  );
  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Date/Time',
        accessor: 'time',
        Cell: ({ value }: any) => {
          return moment(value).format('MM/DD/yyyy HH:mm');
        },
      },
      { Header: 'Student Name', accessor: 'studentName' },
      { Header: 'Order Id', accessor: 'orderId' },
      { Header: 'User Email', accessor: 'actor.email' },
      { Header: 'Action', accessor: 'action' },
      { Header: 'Description', accessor: 'description' },
    ],
    [],
  );

  const updateSortInternal = useCallback(
    (field: SortingRule<Document>[]) => {
      if (!updateSort) return;
      updateSort(field);
    },
    [updateSort],
  );

  const initPage = controlledPage ? controlledPage - 1 : 0;

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setSortBy,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: audits,
      manualPagination: true,
      pageCount: totalPages,
      initialState: { pageSize: selectedPageSize, pageIndex: initPage, sortBy: initialSort },
      manualSortBy: true,
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    if (pageSize !== selectedPageSize) updatePageSize(pageSize);
    if (!updatePage || !controlledPage || pageIndex + 1 === controlledPage) return;
    updatePage(pageIndex + 1);
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (sortBy) {
      updateSortInternal(sortBy);
    }
  }, [sortBy]);

  return (
    <div className={styles.tableContainer}>
      <AuditLogSearchBar
        filters={filters}
        updateUserFilter={updateUserFilter}
        updateActionFilter={updateActionFilter}
        updateStartDate={updateStartDate}
        updateEndDate={updateEndDate}
        userFilter={userFilter}
        actionFilter={actionFilter}
        startDate={startDate}
        endDate={endDate}
        exportAuditData={exportAuditData}
        filterAuditData={filterAuditData}
      />
      {audits.length > 0 ? (
        <>
          <div className={styles.tableContainer}>
            <Card>
              <Table bordered striped hover size="sm" id="auditTable" responsive>
                <thead className={styles.tableHeader}>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        if (column.Header === 'Time') {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{ paddingLeft: '1.2500em' }}>
                              {column.render('Header')}
                              {/* Add a sort direction indicator */}
                              <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                            </th>
                          );
                        } else {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render('Header')}
                              {/* Add a sort direction indicator */}
                              <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                            </th>
                          );
                        }
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          if (cell.column.Header === 'Time') {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <td style={{ paddingLeft: '1.25em' }} {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            );
                          } else {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
          <Row style={{ marginTop: '.5em' }}>
            <Col>{totalCount} records</Col>
            <Col className="pagination" style={{ justifyContent: 'end' }}>
              <div style={{ marginRight: '.5em' }}>
                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </Button>{' '}
                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
                </Button>{' '}
                <Button onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </Button>{' '}
                <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  {'>>'}
                </Button>{' '}
              </div>
              <div style={{ marginRight: '.5em' }}>
                <span>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{' '}
                </span>
              </div>
              <Input
                style={{ maxWidth: '10em' }}
                type="select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
          <br />
          <br />
        </>
      ) : (
        <Alert color="warning" className={styles.noResultsAlert}>
          No results found...
        </Alert>
      )}
    </div>
  );
};

export default AuditLogTable;
