import { CRMIntegrationConfig } from '../types/crmintegration/config';
import { getAuthToken, getBaseApiUrl } from './helpers';

export const getCRMConfig = async (accountId: string): Promise<CRMIntegrationConfig | undefined> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/${accountId}/crm/config`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    const responseBody = await response.json();
    return Object.keys(responseBody).length === 0 ? undefined : (responseBody as CRMIntegrationConfig);
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const updateCRMConfig = async (accountId: string, config: CRMIntegrationConfig): Promise<number> => {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(config),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/crm/config`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const testSftpConnection = async (accountId: string, sftpUrl: string, username: string, sftpKey: string): Promise<void> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ sftpUrl, username, sftpKey }),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/crm/test`, requestOptions);
  if (response.ok) {
    const result = await response.json();
    if (result === true) {
      return Promise.resolve();
    } else {
      return Promise.reject('Connection test failed');
    }
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const sendFile = async (accountId: string): Promise<void> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/crm/send`, requestOptions);
  if (response.ok) {
    Promise.resolve(true);
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const updateCRMStatus = async (accountId: string, documents: string[], newStatus: string): Promise<void> => {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ documents, newStatus }),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/crm/status`, requestOptions);
  if (response.ok) {
    Promise.resolve(true);
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};
