import React, { FunctionComponent, useState } from 'react';
import AuthService from './authservice';

export const AuthContext = React.createContext({
  logout: () => ({}),
  login: (email: string, password: string, onError: (error: string) => void, onNewPasswordRequired: (userAttributes: any) => void) => ({}),
  isAuthenticated: () => ({}),
  signinRedirect: () => ({}),
});

interface AuthProviderProps {
  children: JSX.Element | JSX.Element[];
  authProvider: AuthService;
}
export const AuthProvider = (props: AuthProviderProps) => {
  const [authService] = useState<AuthService>(props.authProvider);
  return <AuthContext.Provider value={authService}>{props.children}</AuthContext.Provider>;
};

export const AuthConsumer = AuthContext.Consumer;
