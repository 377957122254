import { CRMStatusReverseMap } from '../../types/document';

interface GenerateQueryParams {
  createdStartDate?: Date;
  createdEndDate?: Date;
  modifiedStartDate?: Date;
  modifiedEndDate?: Date;
  dobStartDate?: Date;
  dobEndDate?: Date;
  selectedTypes?: string[];
  freeTextSearch?: string;
  statusSearch?: string;
  formAdvancedData: FormAdvancedData;
}

export interface FormAdvancedData {
  orderId: string[];
  appId: string[];
  firstNames: string[];
  lastNames: string[];
  studentId: string[];
  districts: string[];
  schools: string[];
  docTypes: string[];
  crmStatus: string[];
}

export const generateDateRange = (fieldName: string, startDate?: Date, endDate?: Date) => {
  if (startDate) {
    startDate.setUTCHours(0, 0, 0, 0);
  }
  if (endDate) {
    endDate.setUTCHours(23, 59, 59, 999);
  }
  const startDateVal = startDate ? startDate.getTime() : '*';
  const endDateVal = endDate ? endDate.getTime() : '*';

  return `${fieldName}:[${startDateVal} TO ${endDateVal}]`;
};

export const generateStatusQuery = (status: string) => {
  switch (status) {
    case 'RECEIVED':
      return `(_missing_:lastViewDate AND _missing_:downloaded AND expires: [${Date.now()} TO *]) OR status:'Received'`;
    case 'REVIEWED':
      return `(_exists_:lastViewDate AND expires: [${Date.now()} TO *] AND _missing_:downloaded) OR status:'Reviewed'`;
    case 'COMPLETE':
      return `(_exists_:downloaded AND expires: [${Date.now()} TO *]) OR status:'Complete'`;
    case 'EXPIRED':
      return `(expires: [ * TO ${Date.now()}]) OR status:'Expired'`;
    default:
      return '*';
  }
};

const generateFreeTextQuery = (freeTextSearch: string) => {
  const queryValues = [];

  queryValues.push(`metaData.firstname:*${freeTextSearch}*`);
  queryValues.push(`metaData.middlename:*${freeTextSearch}*`);
  queryValues.push(`metaData.lastname:*${freeTextSearch}*`);
  return `(${queryValues.join(' OR ')})`;
};

export const generateQuery = ({
  createdStartDate,
  createdEndDate,
  modifiedStartDate,
  modifiedEndDate,
  dobStartDate,
  dobEndDate,
  freeTextSearch,
  statusSearch,
  formAdvancedData,
}: GenerateQueryParams): string => {
  const queryValues: string[] = [];

  if (createdStartDate || createdEndDate) {
    queryValues.push(generateDateRange('created', createdStartDate, createdEndDate));
  }

  if (modifiedStartDate || modifiedEndDate) {
    queryValues.push(generateDateRange('lastViewDate', modifiedStartDate, modifiedEndDate));
  }

  if (dobStartDate || dobEndDate) {
    queryValues.push(generateDateRange('metaData.dobvalue', dobStartDate, dobEndDate));
  }

  if (statusSearch) {
    queryValues.push(generateStatusQuery(statusSearch));
  }

  if (freeTextSearch) {
    queryValues.push(generateFreeTextQuery(freeTextSearch));
  }

  const advancedQuery = generateFormAdvancedDataQuery(formAdvancedData);
  if (advancedQuery) {
    queryValues.push(advancedQuery);
  }

  console.log('the query is: ' + queryValues.join(' AND '));
  return queryValues.join(' AND ');
};

const fieldMappings: { [key in keyof FormAdvancedData]?: string } = {
  orderId: 'attachmentOrderId',
  appId: 'submissionId',
  firstNames: 'metaData.firstname',
  lastNames: 'metaData.lastname',
  studentId: 'studentid',
  districts: 'metaData.districtname',
  schools: 'metaData.last_school_of_attendance',
  docTypes: 'documentType',
  crmStatus: 'metaData.crmStatus',
};

export const generateCRMStatusQuery = (key: string, values: string[]) => {
  const joined = values
    .map((val) => {
      let undefinedModifier = '';
      if (val === 'N/A') undefinedModifier = ` OR _missing_:${key}`;
      return `(${key}:${CRMStatusReverseMap[val]}${undefinedModifier})`;
    })
    .join(' OR ');
  return `(${joined})`;
};

export const generateFormAdvancedDataQuery = (data: FormAdvancedData): string => {
  return Object.entries(data)
    .filter(([_, value]) => Array.isArray(value) && value.length > 0)
    .map(([key, value]) => {
      const mappedKey = fieldMappings[key as keyof FormAdvancedData] || key;
      if (key === 'crmStatus') return generateCRMStatusQuery(mappedKey, value);

      if (value.length === 1) {
        if (!value[0].trim()) {
          return '';
        }
        return `${mappedKey}:"${value[0]}"`;
      } else {
        const orJoinedValues = value.map((val: string) => `${mappedKey}:"${val}"`).join(' OR ');
        return `(${orJoinedValues})`;
      }
    })
    .filter((queryPart) => queryPart !== '')
    .join(' AND ');
};
