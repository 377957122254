import React, { Dispatch, SetStateAction, useState } from 'react';
import { MdClear } from 'react-icons/md';
import { Card, CardBody, Form, Row, Col, FormGroup, Input, Button, UncontrolledTooltip } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import UserInviteModal from '../userinvite/userInvitemodal';
import { StatusFilter } from '../userlist';
import AdminUserInviteModal from '../../account/apiusers/userinvite/userinvitemodal';
import { toast } from 'react-toastify';
import { inviteScribblesAdmin } from '../../api/users';
import AccessControl from '../userprofile/accesscontrol';

interface searchBarProps {
  updateSearchText: Dispatch<SetStateAction<string>>;
  updateStatusFilter: Dispatch<SetStateAction<StatusFilter>>;
  searchText?: string;
  statusFilter?: StatusFilter;
  accountId: string;
  subListStyle: boolean;
  fetchUsers?: () => Promise<void>;
  adminList?: boolean;
}

const SearchBar = ({
  updateSearchText,
  updateStatusFilter,
  searchText,
  statusFilter,
  accountId,
  subListStyle,
  fetchUsers,
  adminList,
}: searchBarProps): JSX.Element => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const clearFilters = () => {
    updateSearchText('');
  };

  const [modalSubmitting, setModalSubmitting] = useState<boolean>(false);

  const _inviteAdminUser = async (email: string) => {
    await toast.promise(inviteScribblesAdmin(email), {
      pending: 'Sending Request',
      success: 'Invite Sent',
      error: {
        render(error) {
          return error.data;
        },
      },
    });
    toggle();
  };

  const inviteAdminUser = async (email: string) => {
    setModalSubmitting(true);
    try {
      await _inviteAdminUser(email);
    } catch (e) {
      toast.error('Unable to invite this user, please try again.');
      console.log(e);
    } finally {
      setModalSubmitting(false);
    }
  };

  return (
    <div>
      <Card style={{ marginTop: '1em', borderRadius: '.5em', paddingTop: '0em' }}>
        <CardBody className={styles.searchBarHeader}>
          <div>
            <h5 className={styles.searchBarTitle}>Users</h5>
          </div>
        </CardBody>
        <CardBody className={styles.searchBarBody}>
          <Form>
            <Row form>
              <AccessControl permissionId={'users'} action={'read'}>
                <>
                  <Col>
                    <FormGroup>
                      <Input
                        type="text"
                        id="search"
                        placeholder="Search Name, ID"
                        value={searchText}
                        onChange={(e) => updateSearchText(e.target.value.toLowerCase())}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup>
                      <Input
                        value={statusFilter}
                        type="select"
                        name="status"
                        id="status"
                        onChange={(e) => updateStatusFilter(e.target.value as StatusFilter)}
                        required
                      >
                        <option value="ACTIVE">Active</option>
                        <option value="INACTIVE">Inactive</option>
                        <option value="PENDING_INVITE">Pending Invite</option>
                        <option value="*">All</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </>
              </AccessControl>

              <Col md=".1">
                <AccessControl permissionId={'users'} action={'read'}>
                  <>
                    <Button id="clearFilterBtn" className={styles.filterBtn} onClick={clearFilters}>
                      <MdClear />
                    </Button>
                    <UncontrolledTooltip target="clearFilterBtn">Clear Search</UncontrolledTooltip>
                  </>
                </AccessControl>
                <AccessControl
                  requiredPermissionList={[
                    { permissionId: 'users', action: 'create' },
                    { permissionId: 'roles', action: 'read' },
                  ]}
                >
                  <Button
                    id="scrbBtnBlueMargin"
                    onClick={toggle}
                    className={styles.scrbBtnBlueMargin}
                    style={{ display: subListStyle ? 'none' : 'inline-block' }}
                  >
                    {`Invite ${adminList ? 'Scribbles Admin' : 'User'}`}
                  </Button>
                </AccessControl>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      {adminList ? (
        <AdminUserInviteModal toggle={toggle} modal={modal} scribblesAdmin={true} inviteUser={inviteAdminUser} isSubmitting={modalSubmitting} />
      ) : (
        <UserInviteModal toggle={toggle} modal={modal} accountId={accountId} fetchUsers={fetchUsers} />
      )}
    </div>
  );
};

export default SearchBar;
