import fetch from 'node-fetch';
import { Account } from '../types/account';
import { User, UserPartial } from '../types/user';
import { getAuthToken, getBaseApiUrl } from './helpers';
import { RequestInit } from 'node-fetch';

export const getAllColleges = async (): Promise<any[]> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/college/getAllColleges`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });

  if (response.ok) {
    return response.json() as Promise<any[]>;
    return Promise.resolve(response.json());
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

const splitArray = (arr: any[], chunkSize: number): any[] => {
  const result = [];

  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    result.push(chunk);
  }

  return result;
};

export const insertMultipleAccounts = async (listColleges: any[]) => {
  const arrColleges = splitArray(listColleges, 1000);
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(arrColleges),
  };

  const url = `${getBaseApiUrl()}/v1/api/forward/accounts/insertMultipleAccounts`;
  await fetch(url, requestOptions);
};

export const getAccounts = async (): Promise<Account[]> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/accounts`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<Account[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getAccount = async (accountId: string): Promise<Account> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/accounts/${accountId}`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<Account>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const updateAccount = async (newAccount: boolean, accountInfo: Partial<Account>): Promise<number> => {
  const method = newAccount ? 'PUT' : 'PATCH';
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(accountInfo),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/accounts`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const exportAccounts = async (accountId: string, email: string, userId: string, query: string): Promise<number> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ query, email, userId }),
  };

  const url = `${getBaseApiUrl()}/v1/api/forward/${accountId}/document/_export`;
  const response = await fetch(url, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getExportUserData = async () => {
  const url = `${getBaseApiUrl()}/v1/api/forward/report/users`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return Promise.resolve('ok');
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getAccountUsers = async (id: string): Promise<User[]> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/user/_account/${id}`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<User[]>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getUser = async (id: string): Promise<User> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/user/${id}`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<User>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const updateUser = async (newUser: boolean, userData: UserPartial): Promise<number> => {
  console.log('newUser', newUser);
  console.log('userData', userData);
  const method = newUser ? 'PUT' : 'PATCH';
  const requestOptions = {
    method: method,
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(userData),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/user`, requestOptions);

  if (response.status === 404) {
    return Promise.reject('Error 404');
  } else if (response.status !== 200) {
    return Promise.reject('Call Failed: ' + response.status);
  }

  if (!userData.accountMembership || !newUser) {
    return response.status;
  }

  const updatedUser = await response.json();
  const userid = updatedUser.id;

  for (const membership of userData.accountMembership) {
    await addUserToAccount(membership.accountId, userid, membership.roles);
  }

  return response.status;
};

// /accountUserTestID/user/addaccountUserId
export const addUserToAccount = async (accountId: string, userid: string, roles?: string[]): Promise<number> => {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(roles ? roles : {}),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/user/${userid}`, requestOptions);
  if (response.status === 404) {
    return Promise.reject('Error 404');
  }
  return response.status;
};
