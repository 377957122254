import fetch from 'node-fetch';
import { getAuthToken, getBaseApiUrl } from './helpers';
import { PDFDocument } from 'pdf-lib';
import JSZip from 'jszip';
import { Document, QueuedDownload } from '../types/document';

export const searchDocuments = async (
  accountId: string,
  query: string,
  curPage?: number,
  pageSize?: number,
  sort?: string | string[],
): Promise<{ documents: Document[]; count: number }> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify({ query, curPage, pageSize, sort }),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/document/_search`, requestOptions);
  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getUserDownloads = async (accountId: string, userId: string): Promise<QueuedDownload[]> => {
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/downloads/${userId}`, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const downloadingDocuments = async (accountId: string, userId: string, fileName: string, documents: Document[]) => {
  const body = {
    docIds: documents,
    fileName: fileName,
  };

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/downloads/${userId}`, requestOptions);

  if (response.ok) {
    return response.json();
  }
};

const getDocumentsContent = async (accountId: string, documents: any[]) => {
  const fileResult: any[] = [];
  // call the server so we can check auth and get our cookies set
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/document/contents`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: await getAuthToken(),
    },
    body: JSON.stringify({ documents }),
  });
  if (response.ok) {
    const list = await response.json();
    for (const element of list) {
      const result = await fetchS3PresignedObject(element.fileUrl);
      fileResult.push({ fileName: element.fileName, content: result, downloaded: element.downloaded });
    }
    return fileResult;
  } else {
    return [];
  }
};

export const concatPDFs = async (pdfs: any[]) => {
  const mergedPdf = await PDFDocument.create();
  for (const pdf of pdfs) {
    try {
      const pdfBytes = new Uint8Array(pdf.content);

      const pdfDoc = await PDFDocument.load(pdfBytes);

      const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    } catch (error) {
      console.log(error);
    }
  }

  const mergedPdfBytes = await mergedPdf.save();
  const file = { mergedPdfBytes: mergedPdfBytes, downloaded: pdfs[0].downloaded };
  return file;
};

const getMergedDocumentsContent = async (accountId: string, documents: any[]) => {
  const pdfs = await getDocumentsContent(accountId, documents);
  if (pdfs.length === 0) {
    return null;
  }

  const pdfFile = await concatPDFs(pdfs);
  return pdfFile;
};

export const downloadZipFile = async (accountId: string, documents: Document[]) => {
  try {
    const files = await getDocumentsContent(accountId, documents);
    if (files !== null) {
      if (files?.length === 0) {
        return;
      }

      const zip = new JSZip();

      for (const file of files) {
        zip.file(file.fileName, file.content);
      }

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      const objectUrl = URL.createObjectURL(zipBlob);
      const downloadLink = document.createElement('a');
      downloadLink.href = objectUrl;
      downloadLink.download = 'merged_documents.zip';
      downloadLink.click();

      URL.revokeObjectURL(objectUrl);
      return files[0].downloaded;
    }
  } catch (error) {
    console.log('Error: ' + error);
  }
};

export const displayMergedPDF = async (accountId: string, documents: any[]) => {
  const pdfFormat = 'application/pdf';
  const diffPDF = documents.filter((document) => document.contentType != pdfFormat);
  if (diffPDF.length > 0) {
    return 0;
  }
  const file = await getMergedDocumentsContent(accountId, documents);
  if (file == null) {
    return 0;
  }

  if (file.mergedPdfBytes !== null) {
    const mergedPdfBlob = new Blob([file.mergedPdfBytes], { type: pdfFormat });
    const objectUrl = URL.createObjectURL(mergedPdfBlob);
    window.open(objectUrl);

    //URL.revokeObjectURL(objectUrl); this method deleted the url and the browser couldn't find it to download the doc
    return file.downloaded;
  } else {
    console.error('No Content!');
  }
  return 0;
};

export const getDocumentContent = async (accountId: string, documentid: string) => {
  // call the server so we can check auth and get our cookies set
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/document/${documentid}/content`, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    const presignedUrl = await response.json();
    const content = await fetchS3PresignedObject(presignedUrl.fileUrl);
    return { lastViewDate: presignedUrl.lastViewDate, content: content };
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const fetchS3PresignedObject = async (url: string) => {
  const response = await fetch(url, {
    method: 'GET',
  });

  if (response.ok) {
    return response.arrayBuffer();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const getQueuedDownloadContent = async (accountId: string, userId: string, downloadId: string) => {
  // call the server so we can check auth and get our cookies set
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/downloads/${userId}/${downloadId}/content`, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    const presignedUrl = await response.text();
    window.open(presignedUrl, '_blank');
    //return fetchS3PresignedObject(presignedUrl);
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const fetchFilters = async (accountId: string): Promise<{ filters: any }> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/${accountId}/document/_filter`, requestOptions);
  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};
