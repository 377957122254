import React, { useState, useEffect, FormEvent } from 'react';
import AccountContact from './accountContact';
import styles from '../../styles/archiveui.module.css';
import { Col, Row, Button, Form, FormGroup, FormFeedback, Label, Input, ListGroup, Alert, CardBody } from 'reactstrap';
import { withRouter, useParams } from 'react-router';
import { updateAccount } from '../../api/accounts';
import { getAccount } from '../../api/accounts';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Account, Contact } from '../../types/account';
import { LoadingComponent } from '../../Loading';
import { useUserContext } from '../../contexts/UserContext';
import { validatePhoneNumber } from '../../utils/phone';
import { endOfDayUTC } from '../../utils/date';

interface DetailParams {
  accountId: string | undefined;
}

interface DetailProps {
  accountId?: string;
  initialAccount?: boolean;
}

export const AccountDetail = ({ initialAccount, accountId }: DetailProps) => {
  const history = useHistory();
  const [contactErrors, setContactErrors] = useState<Record<number, string | undefined>>({});
  const [isRequired, setRequired] = useState<boolean>();

  const contacts: Contact[] = [];
  const [fullAccount, setAllValues] = useState<{
    status: string;
    shortName: string;
    name: string;
    ceeb: string;
    ipeds: string;
    eTransEmail: string;
    description: string;
    trialVersion: string;
    expirationDate: string;
    contacts: Contact[];
    crmStatus: string | undefined;
  }>({
    status: '',
    shortName: '',
    name: '',
    ceeb: '',
    ipeds: '',
    eTransEmail: '',
    description: '',
    trialVersion: '',
    expirationDate: '',
    contacts: contacts,
    crmStatus: undefined,
  });
  const [error, setError] = useState<string | null>(null);

  const [partialAccount, setPartialAccount] = useState<Partial<Account>>({
    accountId: accountId ? accountId : '',
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getCurrentAccount = async () => {
    if (!accountId) return;
    const currentAccount = await getAccount(accountId);
    setAllValues(() => {
      return {
        ...fullAccount,
        name: currentAccount.name,
        shortName: currentAccount.shortName,
        ceeb: currentAccount.ceeb,
        ipeds: currentAccount.ipeds,
        eTransEmail: currentAccount.eTransEmail,
        description: currentAccount.description,
        status: currentAccount.status,
        trialVersion: currentAccount.trialVersion,
        expirationDate: currentAccount.expirationDate ? new Date(currentAccount.expirationDate).toISOString().split('T')[0] : '',
        contacts: currentAccount.contacts,
        crmStatus: currentAccount.crmStatus,
      };
    });
  };

  const setInitialState = async () => {
    if (accountId && accountId !== '' && fullAccount.name === '') {
      await getCurrentAccount();
    } else if (fullAccount.contacts.length < 1) {
      addContactHandler();
    }
    setLoading(false);
  };

  useEffect(() => {
    setInitialState();
  }, []);

  const addToPartialAccount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const partialUpdates = { [name]: name === 'expirationDate' ? endOfDayUTC(value) : value };

    const fullUpdates = { [name]: value };

    if (name === 'trialVersion' && value === 'true') {
      partialUpdates.status = 'premium';
      fullUpdates.status = 'premium';
      setRequired(true);
    } else if (name === 'trialVersion' && value === 'false') {
      partialUpdates.expirationDate = '';
      fullUpdates.expirationDate = '';
      setRequired(false);
    }

    if (name === 'status' && (value === 'basic' || value === 'inactive')) {
      partialUpdates.trialVersion = 'false';
      fullUpdates.trialVersion = 'false';
    }

    setPartialAccount((prevValues) => ({ ...prevValues, ...partialUpdates }));
    setAllValues((prevValues) => ({ ...prevValues, ...fullUpdates }));
  };

  const _saveAccountHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newAccount = !accountId || accountId === '';
    if (!fullAccount.contacts || fullAccount.contacts.length <= 0) {
      setError('You must add at least one contact.');
      return;
    }

    let primarySet = false;
    let validPhones = true;
    fullAccount.contacts.forEach((contact, index) => {
      if (contact.isPrimary) primarySet = true;
      if (!validatePhone(contact.phone, index)) validPhones = false;
    });

    if (!primarySet) {
      setError('One contact must be designated as the primary contact.');
      return;
    }

    if (!validPhones) return;

    if (partialAccount.trialVersion === 'false') {
      partialAccount.expirationDate = undefined;
    }

    const response = await toast.promise(updateAccount(newAccount, partialAccount), {
      pending: 'Updating Account',
      success: 'Account Updated',
      error: 'There was an error',
    });
    if (response === 200) {
      if (initialAccount) {
        window.location.reload();
      } else {
        history.push('/accountlist');
      }
    } else {
      setError('There was an error updating this account, please try again.');
    }
  };

  const saveAccountHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await _saveAccountHandler(event);
    } catch (e) {
      toast.error('There was an error saving this account, please try again.');
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const contactUpdateHandler = (targetName: string, value: string, index: number) => {
    const contactsCopy = [...fullAccount.contacts];
    const nameOfInput = targetName;
    const objectToUpdate = contactsCopy[index];
    if (nameOfInput == 'name') {
      objectToUpdate.name = value;
    } else if (nameOfInput == 'email') {
      objectToUpdate.email = value;
    } else if (nameOfInput == 'phone') {
      objectToUpdate.phone = value;
    } else if (nameOfInput == 'role') {
      objectToUpdate.role = value;
    }
    contactsCopy[index] = objectToUpdate;
    setAllValues((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
    setPartialAccount((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
  };

  const updatePrimaryContact = (isPrimary: boolean, indexOfContact: number) => {
    const updatedValues = [...fullAccount.contacts].map((contact, index) => {
      if (index !== indexOfContact) {
        contact.isPrimary = false;
      } else {
        contact.isPrimary = isPrimary;
      }
      return contact;
    });

    setAllValues((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: updatedValues,
      };
    });
    setPartialAccount((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: updatedValues,
      };
    });
  };

  const validatePhone = (phoneNumber: string, index: number) => {
    console.log('going to validate', phoneNumber, index);
    if (validatePhoneNumber(phoneNumber)) {
      console.log('is valid');
      setContactErrors((prev) => {
        return {
          ...prev,
          [index]: undefined,
        };
      });
      return true;
    } else {
      console.log('not valid');
      setContactErrors((prev) => {
        return {
          ...prev,
          [index]: 'Invalid Phone Number',
        };
      });
      return false;
    }
  };

  const addContactHandler = () => {
    const contactsCopy = [...fullAccount.contacts];
    if (contactsCopy.length < 1) {
      contactsCopy.push({
        name: '',
        email: '',
        phone: '',
        role: '',
        isPrimary: true,
      });
    } else {
      contactsCopy.push({
        name: '',
        email: '',
        phone: '',
        role: '',
        isPrimary: false,
      });
    }
    setAllValues((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });

    setPartialAccount((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
  };

  const removeContactHandler = (indexOfContact: number) => {
    const contactsCopy = [...fullAccount.contacts];
    contactsCopy.splice(indexOfContact, 1);
    setAllValues((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
    setPartialAccount((prevValues) => {
      return {
        ...prevValues,
        ['contacts']: contactsCopy,
      };
    });
  };

  let errorMessage = null;
  if (error) {
    errorMessage = (
      <Alert color="danger" style={{ marginTop: '3px' }}>
        {error}
      </Alert>
    );
  }

  const [touched, setTouched] = useState({
    status: false,
    shortName: false,
    name: false,
    ceeb: false,
    ipeds: false,
    description: false,
    trialVersion: false,
    expirationDate: false,
  });

  const handleBlur = (field: string) => {
    setTouched({ ...touched, [field]: true });
  };

  const validate = (status: string, shortName: string, name: string, ceeb: string, ipeds: string, description: string, trialVersion: string) => {
    const errors = {
      status: '',
      shortName: '',
      name: '',
      ceeb: '',
      ipeds: '',
      description: '',
      trialVersion: '',
    };

    if (touched.status && status.trim().length < 1) {
      errors.status = 'Status is a required field';
    }
    if (touched.shortName && shortName.trim().length < 1) {
      errors.shortName = 'Short Name is a required field';
    }
    if (touched.name && name.trim().length < 1) {
      errors.name = 'Name is a required field';
    }
    if (touched.ceeb && ceeb.trim().length < 1) {
      errors.ceeb = 'CEEB Code is a required field';
    }
    if (touched.ipeds && ipeds.trim().length < 1) {
      errors.ipeds = 'IPEDS Code is a required field';
    }
    if (touched.description && description.trim().length < 1) {
      errors.description = 'Description is a required field';
    }
    if (touched.trialVersion && trialVersion.trim().length < 1) {
      errors.trialVersion = 'trial Version is a required field';
    }

    return errors;
  };

  const formErrors = validate(
    fullAccount.status,
    fullAccount.shortName,
    fullAccount.name,
    fullAccount.ceeb,
    fullAccount.ipeds,
    fullAccount.description,
    fullAccount.trialVersion,
  );

  return (
    <LoadingComponent isLoading={loading}>
      <div>
        {initialAccount && (
          <Alert style={{ marginTop: '15px' }} color="info">
            No accounts created yet, you must create one to continue...
          </Alert>
        )}
        <div className={styles.detailContainer}>
          <CardBody className={styles.searchBarHeader}>
            <h5 className={styles.searchBarTitle}>Client Detail</h5>
          </CardBody>
          <CardBody>
            <Form onSubmit={(e) => saveAccountHandler(e)}>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      value={fullAccount.name}
                      type="text"
                      name="name"
                      id="name"
                      invalid={formErrors.name !== ''}
                      onBlur={() => handleBlur('name')}
                      onChange={addToPartialAccount}
                      required
                    />
                    <FormFeedback>{formErrors.name}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="shortName">Short Name</Label>
                    <Input
                      value={fullAccount.shortName}
                      type="text"
                      name="shortName"
                      id="shortName"
                      invalid={formErrors.shortName !== ''}
                      onBlur={() => handleBlur('shortName')}
                      onChange={addToPartialAccount}
                      required
                    />
                    <FormFeedback>{formErrors.shortName}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={3}>
                  <FormGroup>
                    <Label for="ceeb">CEEB Code</Label>
                    <Input
                      value={fullAccount.ceeb}
                      type="text"
                      name="ceeb"
                      id="ceeb"
                      invalid={formErrors.ceeb !== ''}
                      onBlur={() => handleBlur('ceeb')}
                      onChange={addToPartialAccount}
                      required
                    />
                    <FormFeedback>{formErrors.ceeb}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="ipeds">IPEDS Code</Label>
                    <Input
                      value={fullAccount.ipeds}
                      type="text"
                      name="ipeds"
                      id="ipeds"
                      invalid={formErrors.ipeds !== ''}
                      onBlur={() => handleBlur('ipeds')}
                      onChange={addToPartialAccount}
                      required
                    />
                    <FormFeedback>{formErrors.ipeds}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="eTransEmail">eTrans Email</Label>
                    <Input value={fullAccount.eTransEmail} type="email" name="eTransEmail" id="eTransEmail" onChange={addToPartialAccount} />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={3}>
                  <FormGroup>
                    <Label for="status">Status</Label>
                    <Input
                      value={fullAccount.status}
                      type="select"
                      name="status"
                      id="status"
                      invalid={formErrors.status !== ''}
                      onBlur={() => handleBlur('status')}
                      onChange={addToPartialAccount}
                      required
                    >
                      <option></option>
                      <option value="basic">Starter</option>
                      <option value="premium">Essentials</option>
                      <option value="inactive">Inactive</option>
                    </Input>
                    <FormFeedback>{formErrors.status}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="status">CRM</Label>
                    <Input
                      value={fullAccount.crmStatus}
                      type="select"
                      name="crmStatus"
                      id="crmStatus"
                      onChange={addToPartialAccount}
                      disabled={fullAccount.status !== 'premium'}
                    >
                      <option value="DISABLED">False</option>
                      <option value="SLATE">Slate</option>
                      <option value="BANNER">Banner</option>
                      <option value="OTHER">Other</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="trialVersion">Trial Version</Label>
                    <Input
                      value={fullAccount.trialVersion}
                      type="select"
                      name="trialVersion"
                      id="trialVersion"
                      invalid={formErrors.trialVersion !== ''}
                      onBlur={() => handleBlur('trialVersion')}
                      onChange={addToPartialAccount}
                      required
                    >
                      <option></option>
                      <option value="false">False</option>
                      <option value="true">True</option>
                      <option value="expired">Expired</option>
                    </Input>
                    <FormFeedback>{formErrors.trialVersion}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="expirationDate">Expiration Date</Label>
                    <Input
                      value={fullAccount.expirationDate}
                      type="date"
                      name="expirationDate"
                      id="expirationDate"
                      onBlur={() => handleBlur('expirationDate')}
                      onChange={(e) => {
                        addToPartialAccount(e);
                      }}
                      required={isRequired}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <FormGroup>
                    <Label for="description">Description</Label>
                    <Input
                      value={fullAccount.description}
                      type="textarea"
                      name="description"
                      id="description"
                      placeholder=""
                      invalid={formErrors.description !== ''}
                      onBlur={() => handleBlur('description')}
                      onChange={addToPartialAccount}
                    ></Input>
                    <FormFeedback>{formErrors.description}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <div className={styles.contactsHeader}>
                <h3>Contacts</h3>
                <div>
                  <Button className={styles.scrbBtnPrimary} size="sm" onClick={addContactHandler}>
                    Add Contact
                  </Button>
                </div>
              </div>
              <ListGroup>
                {fullAccount.contacts.map((contact, index) => {
                  return (
                    <AccountContact
                      name={contact.name}
                      email={contact.email}
                      phone={contact.phone}
                      role={contact.role}
                      isPrimary={contact.isPrimary}
                      key={index}
                      indexOfContact={index}
                      newAccount={accountId && accountId !== '' ? false : true}
                      updateFunction={contactUpdateHandler}
                      updatePrimaryContact={updatePrimaryContact}
                      removeContactHandler={removeContactHandler}
                      error={contactErrors[index]}
                    />
                  );
                })}
                {errorMessage}
              </ListGroup>
              <div className={styles.detailBtnContainer}>
                {!initialAccount && (
                  <Button
                    className={styles.scrbBtnBlueMargin}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button disabled={isSubmitting} className={styles.scrbBtnOrange}>
                  Save
                </Button>
              </div>
            </Form>
          </CardBody>
        </div>
      </div>
    </LoadingComponent>
  );
};

const AccountDetailRoute = () => {
  const { accountId } = useParams<DetailParams>();
  return <AccountDetail accountId={accountId} />;
};

export default withRouter(AccountDetailRoute);
