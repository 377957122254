import React, { useMemo, useState } from 'react';
import { Table, Card, Input, Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import { AiFillEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import SearchBar from './searchbar';
import { useTable, useSortBy, usePagination } from 'react-table';
import { UncontrolledTooltip } from 'reactstrap';
import { Account } from '../../types/account';
import { formatDate, formatTimestampToDate } from '../../utils/date';
import { exportAccounts, getAllColleges, getExportUserData, insertMultipleAccounts } from '../../api/accounts';
import { LoadingComponent } from '../../Loading';
import { DateRange } from '../../document/documentsearch/daterange';
import { generateDateRange } from '../../document/documentsearch/querygeneration';
import { useUserContext } from '../../contexts/UserContext';
import { toast } from 'react-toastify';

interface AccountsListProps {
  accounts: Account[];
  reCallAccounts: () => void;
}

const AccountTable = ({ accounts, reCallAccounts }: AccountsListProps): JSX.Element => {
  const { currentAccount } = useUserContext();
  const { user } = useUserContext().profile;
  const [searchText, updateSearchText] = useState<string>('');
  const [statusFilter, updateStatusFilter] = useState<string>('basic');
  const [loading, updateLoading] = useState<boolean>(false);
  const [modal, setModal] = useState(false);
  const [exportStartDate, setExportStartDate] = useState<Date>();
  const [exportEndDate, setExportEndDate] = useState<Date>();
  const setColleges = async () => {
    updateLoading(true);
    const colleges = await getAllColleges();
    try {
      await insertMultipleAccounts(colleges);
      await reCallAccounts();
      updateLoading(false);
    } catch (error) {
      updateLoading(false);
    }
  };

  const toggle = () => setModal(!modal);

  const exportAccountsData = async () => {
    updateLoading(true);
    try {
      const query = generateDateRange('created', exportStartDate, exportEndDate);
      await exportAccounts(currentAccount.accountId, user.email, user.id, query);
      updateLoading(false);
    } catch (e) {
      console.error(e);
      updateLoading(false);
    }
    toggle();
    toast.info('Export file will be sent to your email shortly');
  };

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name', // accessor is the "key" in the data
      },
      {
        Header: 'Short Name',
        accessor: 'shortName',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props: any) => {
          const s = props.value;
          let displayValue: any;
          switch (s) {
            case 'premium':
              displayValue = 'Essentials';
              break;
            case 'basic':
              displayValue = 'Starter';
              break;
            default:
              displayValue = (s && s[0].toUpperCase() + s.slice(1)) || '';
              break;
          }
          return <>{displayValue}</>;
        },
      },
      {
        Header: 'CRM Status',
        accessor: 'crmStatus',
        // eslint-disable-next-line react/display-name
        Cell: (props: any) => {
          const s = props.value;
          let displayValue = '';
          switch (s) {
            case 'SLATE':
              displayValue = 'Slate';
              break;
            case 'BANNER':
              displayValue = 'Banner';
              break;
            case 'OTHER':
              displayValue = 'Other';
              break;
            default:
              break;
          }
          return <>{displayValue}</>;
        },
      },
      {
        Header: 'Created',
        accessor: 'created',
        // eslint-disable-next-line react/display-name
        Cell: (props: any) => {
          return <>{formatDate(props.date)}</>;
        },
      },
      {
        Header: 'Trial Version',
        accessor: 'trialVersion',
      },
      {
        Header: 'Expiration Date',
        accessor: 'expirationDate',
        // eslint-disable-next-line react/display-name
        Cell: (props: any) => {
          return <>{formatTimestampToDate(props.value)}</>;
        },
      },
      {
        Header: 'Actions',
        // eslint-disable-next-line react/display-name
        Cell: (props: any) => {
          return <>{props.actions}</>;
        },
      },
    ],
    [],
  );

  const checkStatusFilter = (account: Account) => {
    return account.status === statusFilter;
  };

  const checkTextFilter = (account: Account) => {
    return (
      !searchText ||
      account.name.toLowerCase().indexOf(searchText.trim().toLowerCase()) >= 0 ||
      account.shortName.toLowerCase().indexOf(searchText.trim().toLowerCase()) >= 0
    );
  };
  const filteredAccounts = useMemo(() => {
    return accounts.filter((account) => {
      return checkStatusFilter(account) && checkTextFilter(account);
    });
  }, [searchText, statusFilter]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data: filteredAccounts, initialState: { pageIndex: 0, pageSize: 10 } }, useSortBy, usePagination);

  const table = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (table != undefined) {
    table.style.borderRadius = '.5em';
  }

  const exportUserData = async () => {
    await toast.promise(getExportUserData(), {
      pending: 'Starting report generation...',
      error: 'Unable to generate report...',
      success: 'Report is generating, you will receive an email with a download link once it completes.',
    });
  };

  return (
    <div>
      <LoadingComponent isLoading={loading}>
        <div className={styles.tableContainer}>
          <SearchBar
            updateSearchText={updateSearchText}
            updateStatusValue={updateStatusFilter}
            searchText={searchText}
            status={statusFilter}
            exportUserData={exportUserData}
            setColleges={setColleges}
            exportAccounts={toggle}
          />
          {filteredAccounts.length > 0 ? (
            <>
              <Card className={styles.tableContainer}>
                <Table striped hover size="sm" id="auditTable" responsive {...getTableProps()}>
                  <thead className={styles.tableHeader}>
                    {headerGroups.map((headerGroup) => (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => {
                          if (column.Header === 'Name') {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.IdHeader}>
                                {column.render('Header')}
                                {/* Add a sort direction indicator */}
                                <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                              </th>
                            );
                          } else if (column.Header === 'Actions') {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.actionsHeader}>
                                {column.render('Header')}
                                {/* Add a sort direction indicator */}
                                <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                              </th>
                            );
                          } else {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.th}>
                                {column.render('Header')}
                                {/* Add a sort direction indicator */}
                                <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                              </th>
                            );
                          }
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            if (cell.column.Header === 'Name') {
                              return (
                                // eslint-disable-next-line react/jsx-key
                                <td
                                  className={styles.idCell}
                                  onClick={() => {
                                    // onRowClick(cell);
                                  }}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render('Cell')}
                                </td>
                              );
                            } else if (cell.column.Header === 'Actions') {
                              return (
                                // eslint-disable-next-line react/jsx-key
                                <td
                                  className={styles.actionsCell}
                                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
                                  onClick={() => {
                                    // onRowClick(cell);
                                  }}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render('Cell', {
                                    date: cell.row.original.created,
                                    actions: (
                                      <Link to={{ pathname: `/accountdetail/${cell.row.original.accountId}` }}>
                                        <AiFillEdit id="edit" color="#212529" className={styles.pencilIcon} />
                                        <UncontrolledTooltip target="edit">Edit Account</UncontrolledTooltip>
                                      </Link>
                                    ),
                                  })}
                                </td>
                              );
                            } else {
                              return (
                                // eslint-disable-next-line react/jsx-key
                                <td
                                  onClick={() => {
                                    // onRowClick(cell);
                                  }}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render('Cell', {
                                    date: cell.row.original.created,
                                  })}
                                </td>
                              );
                            }
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
              <div className="pagination" style={{ justifyContent: 'end', marginTop: '.5em' }}>
                <div style={{ marginRight: '.5em' }}>
                  <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                  </Button>{' '}
                  <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                  </Button>{' '}
                  <Button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                  </Button>{' '}
                  <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                  </Button>{' '}
                </div>
                <div style={{ marginRight: '.5em' }}>
                  <span>
                    Page{' '}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                  </span>
                </div>
                <Input
                  style={{ maxWidth: '10em' }}
                  type="select"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </Input>
              </div>
              <div>
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader style={{ backgroundColor: '#ceeaf4' }} toggle={toggle}>
                    Export Accounts
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col md={{ offset: 1, size: 10 }}>
                        <DateRange
                          name="accountRange"
                          startDateLabel="Start Date"
                          endDateLabel="End Date"
                          startDateOnChange={setExportStartDate}
                          endDateOnChange={setExportEndDate}
                          isRequired={true}
                        />
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button className={styles.scrbBtnBlue} onClick={toggle}>
                      Cancel
                    </Button>
                    <Button className={styles.scrbBtnOrange} onClick={exportAccountsData}>
                      Export Data
                    </Button>{' '}
                  </ModalFooter>
                </Modal>
              </div>
            </>
          ) : (
            <Alert color="warning" className={styles.noResultsAlert}>
              No results found...
            </Alert>
          )}
        </div>
      </LoadingComponent>
    </div>
  );
};

export default AccountTable;
