import React, { ChangeEvent, useState } from 'react';
import { Modal, ModalHeader, Form, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';
import { Document } from '../../types/document';

interface ConfirmModalProps {
  modal: boolean;
  toggle: () => void;
  onSubmit: (newStatus: string) => void;
}

const CRMModal = ({ modal, toggle, onSubmit }: ConfirmModalProps): JSX.Element => {
  const [newStatus, setNewStatus] = useState<string>('READY');

  const onStatusUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setNewStatus(e.target.value);
  };

  return (
    <div>
      <Modal toggle={toggle} isOpen={modal} style={{ minWidth: '40em' }}>
        <ModalHeader toggle={toggle}>CRM</ModalHeader>
        <Form>
          <ModalBody>
            <FormGroup>
              <Label for="newCRMStatus">Change status to:</Label>
              <Input type="select" name="newCRMStatus" id="newCRMStatus" value={newStatus} onChange={onStatusUpdate}>
                <option value="READY">Ready</option>
                <option value="DO_NOT_SEND">Do Not Send</option>
              </Input>
            </FormGroup>
          </ModalBody>
        </Form>
        <ModalFooter>
          <Button className={styles.scrbBtnBlue} onClick={toggle}>
            Cancel
          </Button>{' '}
          <Button
            className={styles.scrbBtnOrange}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              onSubmit(newStatus);
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CRMModal;
