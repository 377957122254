import fetch from 'node-fetch';
import { User } from '../types/user';
import { UserProfile } from '../users/userprofile';
import { getAuthToken, getBaseApiUrl } from './helpers';

export const getUserProfile = async (): Promise<UserProfile> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/profile`;
  const response = await fetch(url, {
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json() as Promise<UserProfile>;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const updateUserProfile = async (userProfile: Partial<User>): Promise<number> => {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', Authorization: await getAuthToken() },
    body: JSON.stringify(userProfile),
  };
  const response = await fetch(`${getBaseApiUrl()}/v1/api/forward/profile`, requestOptions);
  if (response.ok) {
    return response.status;
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};
