import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Main from './main/main';
import AuthService from './auth/authservice';
import { AuthProvider } from './auth/authprovider';
import { Loading } from './Loading';
import Errorboundary from './errorboundary';
import Login from './public/login';
import ForgotPassword from './public/forgotPassword';
import { IntercomProvider } from 'react-use-intercom';

function App() {
  const [authService, setAuthService] = useState<AuthService>();
  const INTERCOM_APP_ID = 'kk6hpq2j';
  useEffect(() => {
    console.log('auth service useeffect');
    const getAuthService = async () => {
      const service = await AuthService.getInstance();
      setAuthService(() => {
        return service;
      });
    };
    getAuthService();
  }, []);

  let content;

  if (authService?.isAuthenticated()) {
    content = <Main />;
  } else {
    content = <Login />;
  }

  if (authService) {
    return (
      <BrowserRouter>
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true} autoBootProps={{ customAttributes: { scribforward: true } }}>
          <AuthProvider authProvider={authService}>
            <div className="App">
              <Errorboundary>
                <Switch>
                  <Route path="/forgotPassword">
                    <ForgotPassword />
                  </Route>
                  <Route path="/">{content}</Route>
                </Switch>
              </Errorboundary>
            </div>
          </AuthProvider>
        </IntercomProvider>
      </BrowserRouter>
    );
  } else {
    return <Loading></Loading>;
  }
}

export default App;
