import React, { useEffect, useRef } from 'react';
import { LoadingComponent } from '../../Loading';
import DocumentTable from './documentTable';
import { Document } from '../../types/document';

interface DocumentListProps {
  documents?: Document[];
  totalCount: number;
  updatePage: (newPage: number) => void;
  pageSize: number;
  loadingDocuments: boolean;
  currentPage?: number;
  currentSort?: any;
  updateSort: (newSort: any) => void;
  updatePageSize: (size: number) => void;
  refreshDocuments: () => void;
}
const DocumentList = ({
  documents,
  totalCount,
  updatePage,
  pageSize,
  loadingDocuments,
  currentPage,
  currentSort,
  updateSort,
  updatePageSize,
  refreshDocuments,
}: DocumentListProps) => {
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView();
    }
  }, [documents]);

  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <div ref={tableRef}>
      <LoadingComponent isLoading={loadingDocuments}>
        <DocumentTable
          documents={documents}
          updatePage={updatePage}
          selectedPageSize={pageSize}
          totalCount={totalCount}
          updatePageSize={updatePageSize}
          totalPages={totalPages}
          controlledPage={currentPage}
          currentSort={currentSort}
          updateSort={updateSort}
          refreshDocuments={refreshDocuments}
        />
      </LoadingComponent>
    </div>
  );
};

export default DocumentList;
