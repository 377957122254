import React, { useState } from 'react';
import { FaUserTag, FaRegShareSquare } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { ImCheckmark, ImSearch, ImUsers } from 'react-icons/im';
import { RiAdminFill, RiBarChartGroupedLine, RiBriefcase2Fill, RiUserFill } from 'react-icons/ri';
import { Link, useLocation } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavbarBrand, NavItem, NavLink, UncontrolledDropdown, Input } from 'reactstrap';
import styles from '../styles/archiveui.module.css';
import AccessControl from '../users/userprofile/accesscontrol';
import { Account } from '../types/account';
import { useUIContext } from '../contexts/UIContext';
import { useUserContext } from '../contexts/UserContext';
import logo from './scribforward_blue.png';
import birdHead from './bird-logo-head.png';
import { routePermissions } from '../users/userprofile/user';
import { PremiumAccessControl } from '../componentutils/PremiumAccessControl';
import { BsFillInboxFill } from 'react-icons/bs';
import { useIntercom } from 'react-use-intercom';

interface NavigationProps {
  logout: () => void;
}

const SideNav = ({ logout }: NavigationProps) => {
  let currentAccount: Account | undefined = undefined;
  let setCurrentAccount: (account: Account) => void;
  let userAccounts: Account[] = [];
  const { shutdown } = useIntercom();

  try {
    const userContext = useUserContext();
    currentAccount = userContext.currentAccount;
    setCurrentAccount = userContext.setCurrentAccount;
    userAccounts = userContext.userAccounts.sort((a, b) => {
      return ('' + a.shortName).trim().localeCompare(('' + b.shortName).trim());
    });
  } catch (e) {
    console.log('User provider not available...' + e);
  }

  const location = useLocation();
  const mediaQuery = window.matchMedia('( min-width: 768px )');
  const { sideBarOpen, toggleSidebar } = useUIContext();
  const [userSearchText, updateUserSearchText] = useState<string>('');

  const handleLogout = () => {
    shutdown();
    logout();
  };

  const filterAccountByShortName = (name: string) => {
    return userAccounts
      .filter(
        (element) =>
          element.shortName &&
          element.shortName.toLowerCase().includes(name.toLowerCase()) &&
          element.accountId !== currentAccount?.accountId &&
          element.status !== 'inactive',
      )
      .map((element) => element);
  };

  const navItems = currentAccount ? (
    <>
      <div className={styles.navMenuItemContainer}>
        <NavItem className={styles.navMenuItem}>
          <NavLink className={styles.navLink} tag={Link} to={'/inbox'}>
            <BsFillInboxFill color="#ceeaf4" size={25} className={styles.menuBtn} />
            <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Inbox</div>
          </NavLink>
        </NavItem>
        <div
          style={{ display: location.pathname === '/inbox' ? 'block' : 'none' }}
          className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
        ></div>
      </div>
      <AccessControl requiredPermissionList={routePermissions['/users'].hardPermissions} softPermissionList={routePermissions['/users'].softPermissions}>
        <div className={styles.navMenuItemContainer}>
          <NavItem className={styles.navMenuItem}>
            <NavLink className={styles.navLink} tag={Link} to={'/users'}>
              <ImUsers color="#ceeaf4" size={25} className={styles.menuBtn} />
              <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Manage Users</div>
            </NavLink>
          </NavItem>
          <div
            style={{ display: location.pathname === '/users' ? 'block' : 'none' }}
            className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
          ></div>
        </div>
      </AccessControl>
      <AccessControl requiredPermissionList={routePermissions['/roles'].hardPermissions} softPermissionList={routePermissions['/roles'].softPermissions}>
        <div className={styles.navMenuItemContainer}>
          <NavItem className={styles.navMenuItem}>
            <PremiumAccessControl highlight={true} removeIcon={!sideBarOpen}>
              <NavLink className={styles.navLink} tag={Link} to={'/roles'}>
                <FaUserTag color="#ceeaf4" size={25} className={styles.menuBtn} />
                <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Manage Roles</div>
              </NavLink>
            </PremiumAccessControl>
          </NavItem>
          <div
            style={{ display: location.pathname === '/roles' ? 'block' : 'none' }}
            className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
          ></div>
        </div>
      </AccessControl>
      <AccessControl requiredPermissionList={routePermissions['/auditlog'].hardPermissions} softPermissionList={routePermissions['/auditlog'].softPermissions}>
        <div className={styles.navMenuItemContainer}>
          <NavItem className={styles.navMenuItem}>
            <PremiumAccessControl highlight={true} removeIcon={!sideBarOpen}>
              <NavLink className={styles.navLink} tag={Link} to={'/auditlog'}>
                <RiBarChartGroupedLine color="#ceeaf4" size={25} className={styles.menuBtn} />
                <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Audit Log</div>
              </NavLink>
            </PremiumAccessControl>
          </NavItem>
          <div
            style={{ display: location.pathname === '/auditlog' ? 'block' : 'none' }}
            className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
          ></div>
        </div>
      </AccessControl>
      {currentAccount.crmStatus && currentAccount.crmStatus !== 'DISABLED' && (
        <AccessControl permissionId={'crmIntegration'} action={'read'}>
          <div className={styles.navMenuItemContainer}>
            <NavItem className={styles.navMenuItem}>
              <PremiumAccessControl highlight={true} removeIcon={!sideBarOpen}>
                <NavLink className={styles.navLink} tag={Link} to={'/crmconfig'}>
                  <FaRegShareSquare color="#ceeaf4" size={25} className={styles.menuBtn} />
                  <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>CRM Integration</div>
                </NavLink>
              </PremiumAccessControl>
            </NavItem>
            <div
              style={{ display: location.pathname === '/crmconfig' ? 'block' : 'none' }}
              className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
            ></div>
          </div>
        </AccessControl>
      )}
      <AccessControl>
        <>
          <div className={styles.menuDivider}></div>
          <div className={styles.navMenuItemContainer}>
            <NavItem className={styles.navMenuItem}>
              <NavLink className={styles.navLink} tag={Link} to={'/accountlist'}>
                <RiBriefcase2Fill color="#ceeaf4" size={25} className={styles.menuBtn} />
                <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Accounts</div>
              </NavLink>
            </NavItem>
            <div
              style={{ display: location.pathname === '/accountlist' ? 'block' : 'none' }}
              className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
            ></div>
          </div>
          <div className={styles.navMenuItemContainer}>
            <NavItem className={styles.navMenuItem}>
              <NavLink className={styles.navLink} tag={Link} to={'/adminusers'}>
                <RiAdminFill color="#ceeaf4" size={25} className={styles.menuBtn} />
                <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Admin Users</div>
              </NavLink>
            </NavItem>
            <div
              style={{ display: location.pathname === '/adminusers' ? 'block' : 'none' }}
              className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}
            ></div>
          </div>
        </>
      </AccessControl>
    </>
  ) : (
    <div className={styles.navMenuItemContainer}>
      <NavItem className={styles.navMenuItem}>
        <NavLink className={styles.navLink} tag={Link}>
          <RiBriefcase2Fill color="#ceeaf4" size={25} className={styles.menuBtn} />
          <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Create Account</div>
        </NavLink>
      </NavItem>
      <div style={{ display: 'block' }} className={sideBarOpen ? styles.selectedMenuItemOpen : styles.selectedMenuItem}></div>
    </div>
  );

  return (
    <div style={{ width: sideBarOpen ? '13em' : '3em' }} className={styles.sideNav}>
      <div className={styles.navListContainer}>
        <div className={styles.scribLogo}>
          <img src={birdHead} style={{ height: '45px', top: 0, left: 0, transform: 'scaleX(-1)' }} alt="" />
          <img src={logo} style={{ height: '56px', top: 0, left: 0, display: sideBarOpen ? 'flex' : 'none' }} alt="" />
        </div>
        <h4 className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden} style={{ whiteSpace: 'normal' }}>
          {currentAccount?.shortName}
          <div style={{ fontSize: '14px', marginTop: '10px', textAlign: 'left' }}>
            {currentAccount?.trialVersion === 'true' ? (
              <>
                {' '}
                Your trial version of Essentials expires soon!{' '}
                <a style={{ color: '#0056b3' }} href="https://www.scribsoft.com/scribforward" target="_blank" rel="noopener noreferrer">
                  CLICK HERE
                </a>{' '}
                to upgrade{' '}
              </>
            ) : null}
          </div>
        </h4>
        <div className={styles.menuDivider}></div>
        <Nav vertical className="list-unstyled pb-3">
          {navItems}
        </Nav>
      </div>
      <div className={styles.navBarBottom}>
        <div className={styles.navMenuItemContainer}>
          <div className={styles.navMenuItem}>
            <GiHamburgerMenu color="#ceeaf4" size={25} style={{ minWidth: '1.5em' }} className={styles.hamburgerIcon} onClick={toggleSidebar} />
            <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden}>Show/Hide Drawer</div>
          </div>
        </div>
        {currentAccount && (
          <div className={styles.menuNavLinks}>
            <UncontrolledDropdown direction="up" nav inNavbar className={styles.dropdownToggle}>
              <DropdownToggle
                drop={'up'}
                nav
                style={{ color: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '0px', maxWidth: '100%' }}
              >
                <RiUserFill color="#ceeaf4" size={25} className={styles.hamburgerIcon} />
                <div className={sideBarOpen ? styles.menuItemTxt : styles.menuItemTxtHidden} title="User Settings" style={{ paddingLeft: '1px' }}>
                  User Settings
                </div>{' '}
              </DropdownToggle>
              <DropdownMenu style={{ boxShadow: '1px 2px 7px 1px rgb(0 0 0 / 13%)', maxHeight: '690px', overflowY: 'auto' }}>
                <NavLink className={styles.link} tag={Link} to={'/userprofile'}>
                  <DropdownItem>Profile</DropdownItem>
                </NavLink>
                <Input
                  type="text"
                  id="search"
                  className="justify-content-center"
                  placeholder="Search"
                  value={userSearchText}
                  style={{ width: '210px' }}
                  onChange={(e) => {
                    updateUserSearchText(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
                <DropdownItem className="text-primary">
                  {currentAccount?.shortName}
                  <ImCheckmark className={styles.checkMark} />
                </DropdownItem>
                {userAccounts.length > 1 && <DropdownItem divider />}
                {filterAccountByShortName(userSearchText).map((account, index) => (
                  <DropdownItem
                    onClick={() => {
                      setCurrentAccount(account);
                    }}
                    key={account.accountId}
                  >
                    {account.shortName}
                  </DropdownItem>
                ))}

                <DropdownItem divider />
                <DropdownItem onClick={handleLogout}> Log Out </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideNav;
