export const downloadCsv = (filename: string, text: string) => {
  const pom = document.createElement('a');
  pom.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
  pom.setAttribute('download', filename);

  if (document.createEvent) {
    const event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    pom.dispatchEvent(event);
  } else {
    pom.click();
  }
};

export const arrayBufferToBlob = (content: ArrayBuffer): Blob => {
  return new Blob([content], { type: 'application/pdf' });
};
