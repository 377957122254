import React, { useState, useEffect } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';

export interface Option {
  displayValue: string;
  key: string;
}

interface MultiSelectProps {
  options: Option[];
  maxSelections: number;
  setSelectedOptions: (selected: string[]) => void;
  selectedOptions: string[];
}

const MultiSelect: React.FC<MultiSelectProps> = ({ options, maxSelections, setSelectedOptions, selectedOptions }) => {
  const [selected, setSelected] = useState<Option[]>([]);

  useEffect(() => {
    const selectedOpts = options.filter((option) => selectedOptions.includes(option.key));
    setSelected(selectedOpts);
  }, [options, selectedOptions]);

  const onSelect = (selectedList: Option[]) => {
    if (selectedList.length <= maxSelections) {
      setSelectedOptions(selectedList.map((option) => option.key));
    }
  };

  const onRemove = (selectedList: Option[]) => {
    setSelectedOptions(selectedList.map((option) => option.key));
  };

  return (
    <Multiselect
      displayValue="displayValue"
      options={options}
      selectedValues={selected}
      onSelect={onSelect}
      onRemove={onRemove}
      selectionLimit={maxSelections}
    />
  );
};

export default MultiSelect;
