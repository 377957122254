import { generateDateRange } from '../document/documentsearch/querygeneration';

export const generateFieldQuery = (fieldName: string, userSearch: string): string => {
  return `${fieldName}:${userSearch}`;
};

export const generateAuditQuery = (startDate: Date | undefined, endDate: Date | undefined, userSearch: string, actionSearch: string): string => {
  const queryValues: string[] = [];

  if (startDate || endDate) {
    queryValues.push(generateDateRange('time', startDate, endDate));
  }

  if (userSearch) {
    queryValues.push(generateFieldQuery('actor.email', userSearch));
  }

  if (actionSearch) {
    queryValues.push(generateFieldQuery('action', actionSearch));
  }

  console.log('the query is: ' + queryValues.join(' AND '));
  return queryValues.join(' AND ');
};
