import React, { FormEvent, useState, useEffect } from 'react';
import { getRoles } from '../../../api/roles';
import { Modal, ModalHeader, Form, ModalBody, Row, Col, FormGroup, Label, Input, FormFeedback, ModalFooter, Button } from 'reactstrap';
import { Role } from '../../../types/role';
import styles from '../../../styles/archiveui.module.css';

interface UserInviteModalProps {
  modal: boolean;
  toggle: () => void;
  inviteUser: (email: string, roleId: string) => void;
  isSubmitting: boolean;
  accountId?: string;
  scribblesAdmin?: boolean;
}

const UserInviteModal = ({ modal, toggle, inviteUser, accountId, scribblesAdmin, isSubmitting }: UserInviteModalProps) => {
  const [roles, updateRoles] = useState<Role[]>([]);
  const [invitedUser, setInvitedUser] = useState({
    invitedUserEmail: '',
    invitedUserRoleId: '',
  });
  const [touched, setTouched] = useState({
    email: false,
    roleId: false,
    permissions: false,
  });

  const addToInvitedUser = (key: string, value: any) => {
    setInvitedUser((prevValues) => {
      return { ...prevValues, [key]: value };
    });
  };

  useEffect(() => {
    if (accountId) {
      const setRoles = async () => {
        const roles = await getRoles(accountId);
        updateRoles(roles);
      };
      setRoles();
    }
  }, []);

  const handleBlur = (field: string) => {
    setTouched({ ...touched, [field]: true });
  };

  const validate = (email: string) => {
    const errors = {
      email: '',
      roleId: '',
    };

    if (touched.email && !/\S+@\S+\.\S+/.test(email.trim())) {
      errors.email = 'Email provided is not a valid email';
    }

    return errors;
  };

  const formErrors = validate(invitedUser.invitedUserEmail);

  const inviteUserHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formErrors.email && !formErrors.roleId) {
      inviteUser(invitedUser.invitedUserEmail, invitedUser.invitedUserRoleId);
    }
  };

  return (
    <div>
      <Modal toggle={toggle} isOpen={modal} style={{ minWidth: '40em' }}>
        <ModalHeader toggle={toggle}>Invite User</ModalHeader>
        <Form onSubmit={inviteUserHandler}>
          <ModalBody>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    onBlur={() => handleBlur('email')}
                    required
                    invalid={formErrors.email !== ''}
                    onChange={(e) => {
                      addToInvitedUser('invitedUserEmail', e.target.value);
                    }}
                  />
                  <FormFeedback>{formErrors.email}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            {!scribblesAdmin && (
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="description">Role</Label>
                    <Input
                      onBlur={() => handleBlur('roleId')}
                      required
                      invalid={formErrors.roleId !== ''}
                      type="select"
                      onChange={(e) => {
                        addToInvitedUser('invitedUserRoleId', e.target.value);
                      }}
                    >
                      <option></option>
                      {roles.map((role, key) => {
                        return (
                          <option key={key} value={role.id}>
                            {role.name}
                          </option>
                        );
                      })}
                    </Input>
                    <FormFeedback>{formErrors.roleId}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button className={styles.scrbBtnBlue} onClick={toggle}>
              Cancel
            </Button>{' '}
            <Button className={styles.scrbBtnOrange} disabled={isSubmitting} type="submit">
              Send Invitation
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default UserInviteModal;
