import moment from 'moment';

export const formatDate = (date: Date) => {
  return moment(date).format('MM/DD/YYYY');
};

export const formatDateWithTime = (date: Date | string | number) => {
  return moment(date).format('MM/DD/YYYY hh:mm A');
};

export const formatTimestampToDate = (timestamp: number | null) => {
  if (!timestamp) {
    return '';
  }
  //return new Date(timestamp).toLocaleDateString('en-US'); //.format('MM/DD/YYYY');
  return moment.utc(timestamp).format('MM/DD/YYYY');
};

export const endOfDayUTC = (dateString: string) => {
  const date = new Date(dateString);
  date.setUTCHours(23, 59, 59, 999);
  return date.getTime();
};

const isDST = (date: Date) => {
  const year = date.getUTCFullYear();
  const secondSundayOfMarch = new Date(Date.UTC(year, 2, 8 + (14 - new Date(Date.UTC(year, 2, 1)).getUTCDay()))); // 2 is March
  const firstSundayOfNovember = new Date(Date.UTC(year, 10, 1 + (7 - new Date(Date.UTC(year, 10, 1)).getUTCDay()))); // 10 is November

  return date >= secondSundayOfMarch && date < firstSundayOfNovember;
};

export const endOfDayET = (dateString: string) => {
  const date = new Date(dateString);
  date.setUTCHours(23, 59, 59, 999); // Set to end of day in UTC

  // Adjust for Eastern Time (UTC-5 or UTC-4 during DST)
  date.setUTCHours(date.getUTCHours() - (isDST(date) ? 4 : 5));

  return date.getTime();
};
