import fetch from 'node-fetch';
import { AuditEntry, AuditFilterEntry } from '../types/audit';
import { getAuthToken, getBaseApiUrl } from './helpers';

let firstTime: boolean;

export const fetchAuditFilters = async (accountId: string): Promise<{ filters: AuditFilterEntry }> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/${accountId}/audit/_filter`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: await getAuthToken(),
    },
  });
  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

export const searchAuditLog = async (
  accountId: string,
  query: string,
  curPage?: number,
  pageSize?: number,
  sort?: string | string[],
): Promise<{ entries: AuditEntry[]; count: number }> => {
  const url = `${getBaseApiUrl()}/v1/api/forward/${accountId}/audit/_search`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: await getAuthToken(),
    },
    body: JSON.stringify({ query, curPage, pageSize, sort }),
  });
  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return Promise.reject('Error 404');
  } else {
    return Promise.reject('Call Failed: ' + response.status);
  }
};

const buildFilterParam = (param: string, value: string): string => {
  if (!param || !value || value === '') return '';
  const toReturn = `${firstTime ? '?' : '&'}${param}=${value}`;
  if (firstTime) firstTime = false;
  return toReturn;
};
