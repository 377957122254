import React, { ChangeEvent, useState } from 'react';
import styles from '../../styles/archiveui.module.css';
import { Alert, Button, Col, Container, Form, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { DateRange } from './daterange';
import { generateQuery } from './querygeneration';
import { BsPlus } from 'react-icons/bs';
import { AiOutlineMinus } from 'react-icons/ai';
import AdvancedFilterComponent from '../documentadvancedfilter/AdvancedFilterComponent';
import { PremiumAccessControl } from '../../componentutils/PremiumAccessControl';

interface SearchComponentProps {
  submitSearch: (query: string) => void;
  searchError?: string;
  searching: boolean;
  startNewSearch: () => void;
}
const SearchComponent = ({ submitSearch, searchError, searching, startNewSearch }: SearchComponentProps) => {
  const [selectedTypes, setSelectedTypes] = useState<Record<string, boolean>>({});
  const [modifiedTimeStart, setModifiedTimeStart] = useState<Date>();
  const [modifiedTimeEnd, setModifiedTimeEnd] = useState<Date>();
  const [creationTimeStart, setCreationTimeStart] = useState<Date>();
  const [creationTimeEnd, setCreationTimeEnd] = useState<Date>();
  const [dobTimeStart, setDobTimeStart] = useState<Date>();
  const [dobTimeEnd, setDobTimeEnd] = useState<Date>();
  const [freeSearch, setFreeSearch] = useState<string>();
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [statusSearch, setStatusSearch] = useState<string>('*');

  const updateStatusSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setStatusSearch(event.target.value);
  };

  const [formAdvancedData, setAdvancedFormData] = useState({
    orderId: [],
    appId: [],
    firstNames: [],
    lastNames: [],
    studentId: [],
    districts: [],
    schools: [],
    docTypes: [],
    crmStatus: [],
  });

  const submitSearchInternal = async () => {
    const typesToSearch: string[] = [];
    for (const typeId of Object.keys(selectedTypes)) {
      if (selectedTypes[typeId]) {
        typesToSearch.push(typeId);
      }
    }

    const query = generateQuery({
      freeTextSearch: freeSearch,
      createdStartDate: creationTimeStart,
      createdEndDate: creationTimeEnd,
      modifiedStartDate: modifiedTimeStart,
      modifiedEndDate: modifiedTimeEnd,
      dobStartDate: dobTimeStart,
      dobEndDate: dobTimeEnd,
      statusSearch,
      formAdvancedData,
    });

    submitSearch(query);
  };

  const handleClearFilters = () => {
    setFreeSearch('');
    setShowAdvanced(false);
    setStatusSearch('*');
    setCreationTimeEnd(undefined);
    setCreationTimeStart(undefined);
    setModifiedTimeEnd(undefined);
    setModifiedTimeStart(undefined);
    setDobTimeEnd(undefined);
    setDobTimeStart(undefined);
    setAdvancedFormData({ orderId: [], appId: [], firstNames: [], lastNames: [], studentId: [], districts: [], schools: [], docTypes: [], crmStatus: [] });
    startNewSearch();
  };

  const handleUpdate = (field: string, values: string[]) => {
    setAdvancedFormData((prevData) => ({ ...prevData, [field]: values }));
  };

  const containerStyle = {
    marginLeft: '0px',
    marginRight: '0px',
    width: '100%',
    maxWidth: '100%',
  };

  return (
    <Container className={styles.documentsearchContainer} style={containerStyle}>
      <div style={{ marginTop: '10px', marginBottom: '25px' }}>
        <InputGroup>
          <Input
            disabled={searching}
            onChange={(e) => {
              setFreeSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                submitSearchInternal();
              }
            }}
            value={freeSearch}
          />
          <InputGroupAddon addonType="append">
            <Button color="success" onClick={submitSearchInternal} disabled={searching}>
              Search
            </Button>
          </InputGroupAddon>
          <InputGroupAddon addonType="append">
            <Button className={styles.scrbBtnBlue} onClick={() => handleClearFilters()}>
              New Search
            </Button>
          </InputGroupAddon>
          <InputGroupAddon addonType="append">
            <PremiumAccessControl highlight={true}>
              <Button
                onClick={() => setShowAdvanced(!showAdvanced)}
                className={showAdvanced ? '' : styles.scrbBtnOrange}
                style={{ backgroundColor: showAdvanced ? 'gray' : '#f68b2b' }}
                disabled={searching}
              >
                {showAdvanced ? <AiOutlineMinus /> : <BsPlus />}
              </Button>
            </PremiumAccessControl>
          </InputGroupAddon>
        </InputGroup>
        <Row>
          {searchError && searchError !== '' && (
            <Alert color="danger" style={{ width: '100%', margin: '1%', textAlign: 'center' }}>
              {searchError}
            </Alert>
          )}
        </Row>
      </div>
      {showAdvanced && (
        <Form style={{ marginTop: '25px', marginBottom: '25px' }}>
          <Row>
            <Col sm="3">
              <h5>DOB</h5>
              <DateRange name="metadata.dob" startDateLabel="From" endDateLabel="To" startDateOnChange={setDobTimeStart} endDateOnChange={setDobTimeEnd} />
            </Col>
            <Col sm="3">
              <h5>Date Received</h5>
              <DateRange name="created" startDateLabel="From" endDateLabel="To" startDateOnChange={setCreationTimeStart} endDateOnChange={setCreationTimeEnd} />
            </Col>
            <Col sm="3">
              <h5>Date Reviewed</h5>
              <DateRange
                name="reviewed"
                startDateLabel="From"
                endDateLabel="To"
                startDateOnChange={setModifiedTimeStart}
                endDateOnChange={setModifiedTimeEnd}
              />
            </Col>
            <Col sm="3">
              <h5>Date Downloaded</h5>
              <DateRange
                name="downloaded"
                startDateLabel="From"
                endDateLabel="To"
                startDateOnChange={setModifiedTimeStart}
                endDateOnChange={setModifiedTimeEnd}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: '.5em' }}>
            <Col>
              <AdvancedFilterComponent onUpdate={handleUpdate} updateStatusSearch={updateStatusSearch} statusSearch={statusSearch} />
            </Col>
          </Row>
        </Form>
      )}
    </Container>
  );
};

export default SearchComponent;
