import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, Input, DropdownItem, CustomInput } from 'reactstrap';
import styles from '../../styles/archiveui.module.css';

interface SearchableDropdownProps {
  items: string[];
  onSelect: (selectedItems: string[]) => void;
  placeholder?: string;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({ items, onSelect, placeholder = 'Search...' }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleSelect = (item: string) => {
    const updatedSelectedItems = selectedItems.includes(item) ? selectedItems.filter((selectedItem) => selectedItem !== item) : [...selectedItems, item];
    setSelectedItems(updatedSelectedItems);
    onSelect(updatedSelectedItems);
  };

  const filteredItems = items.filter((item) => item.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className={`${styles.lightDropdownToggle} ${styles.capitalizeFirstLetter} ${styles.truncate}`} caret>
        {selectedItems.length > 0 ? selectedItems.join(', ') : 'Select items'}
      </DropdownToggle>
      <DropdownMenu className={`${styles.lightDropdownMenu} ${styles.scrollableDropdown}`}>
        <DropdownItem header>
          <Input placeholder={placeholder} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        </DropdownItem>
        {filteredItems.map((item, index) => (
          <DropdownItem className={styles.capitalizeFirstLetter} key={index} onClick={() => handleSelect(item)} toggle={false}>
            <input
              type="checkbox"
              id={`checkbox-${index}-${item}`}
              className={`${styles.customControlInput}`}
              checked={selectedItems.includes(item)}
              onChange={() => handleSelect(item)}
            />
            {item}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default SearchableDropdown;
