import moment from 'moment';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

interface DateRangeProps {
  name: string;
  startDateLabel: string;
  endDateLabel: string;
  startDateOnChange: (date: Date | undefined) => void;
  endDateOnChange: (date: Date | undefined) => void;
  isRequired?: boolean;
}

export const dateToString = (date: string | number | Date) => {
  // let month = '' + (date.getMonth() + 1);
  // let day = '' + date.getDate();
  // const year = date.getFullYear();

  // if (month.length < 2) month = '0' + month;
  // if (day.length < 2) day = '0' + day;

  // return [year, month, day].join('-');
  return moment(date).utc().format('YYYY-MM-DD');
};

export const DateRange = ({ name, startDateLabel, endDateLabel, startDateOnChange, endDateOnChange, isRequired }: DateRangeProps) => {
  return (
    <>
      <FormGroup>
        <Label for={`${name}_startdate`}>{startDateLabel}</Label>
        <Input
          type="date"
          name={`${name}_startdate`}
          id={`${name}_startdate`}
          onChange={(e) => {
            console.log('changing');
            startDateOnChange(e.target.value ? new Date(e.target.value) : undefined);
          }}
          required={isRequired}
        />
      </FormGroup>
      <FormGroup>
        <Label for={`${name}_enddate`}>{endDateLabel}</Label>
        <Input
          type="date"
          name={`${name}_enddate`}
          id={`${name}_enddate`}
          onChange={(e) => endDateOnChange(e.target.value ? new Date(e.target.value) : undefined)}
          required={isRequired}
        />
      </FormGroup>
    </>
  );
};
