import React, { ChangeEvent } from 'react';
import { Input } from 'reactstrap';

interface StatusInputProps {
  defaultValue: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  showAll?: boolean;
}

export const StatusInput = ({ defaultValue, onChange, showAll }: StatusInputProps) => {
  return (
    <Input type="select" name="docStatus" id="docStatus" onChange={onChange} defaultValue={defaultValue} required={true}>
      <option value="RECEIVED">Received</option>
      <option value="REVIEWED">Reviewed</option>
      <option value="COMPLETE">Complete</option>
      <option value="EXPIRED">Expired</option>
      {showAll && <option value="*">ALL</option>}
    </Input>
  );
};
