import React, { Dispatch, SetStateAction } from 'react';
import { Card, CardBody, Form, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import styles from '../styles/archiveui.module.css';
import { AuditFilterEntry } from '../types/audit';

interface SearchBarProps {
  filters: AuditFilterEntry;
  updateUserFilter: Dispatch<SetStateAction<string>>;
  updateActionFilter: Dispatch<SetStateAction<string>>;
  updateStartDate: Dispatch<SetStateAction<string>>;
  updateEndDate: Dispatch<SetStateAction<string>>;
  userFilter: string;
  actionFilter: string;
  startDate: string;
  endDate: string;
  exportAuditData: () => void;
  filterAuditData: (reset: boolean) => void;
}

const AuditLogSearchBar = ({
  filters,
  updateUserFilter,
  updateActionFilter,
  updateStartDate,
  updateEndDate,
  userFilter,
  actionFilter,
  startDate,
  endDate,
  exportAuditData,
  filterAuditData,
}: SearchBarProps): JSX.Element => {
  const clearFilters = () => {
    updateUserFilter('');
    updateActionFilter('');
    updateStartDate('');
    updateEndDate('');
    filterAuditData(true);
  };

  return (
    <div>
      <Card className={styles.filtersCard}>
        <CardBody className={styles.searchBarHeader}>
          <h5 className={styles.searchBarTitle}>Audit Log</h5>
        </CardBody>
        <CardBody className={styles.searchBarBody}>
          <Form>
            <Row form className={styles.rowContainer}>
              <Col md={6}>
                <label>User Email:</label>
                <FormGroup>
                  <Input type="select" name="actor" id="actor" placeholder="Actor" value={userFilter} onChange={(e) => updateUserFilter(e.target.value.trim())}>
                    <option></option>
                    {filters.userFilter.map((user, key) => {
                      return (
                        <option key={key} value={user}>
                          {user}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <label>Action:</label>
                <FormGroup>
                  <Input type="select" name="result" id="result" placeholder="Result" value={actionFilter} onChange={(e) => updateActionFilter(e.target.value)}>
                    <option></option>
                    {filters.actionFilter.map((action, key) => {
                      return (
                        <option key={key} value={action}>
                          {action}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row form className={styles.rowContainer}>
              <Col md={6}>
                <label>From Date:</label>
                <FormGroup>
                  <Input
                    type="date"
                    name="start-date"
                    id="start-date"
                    placeholder="Start Date"
                    value={startDate}
                    onChange={(e) => updateStartDate(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <label>To Date:</label>
                <FormGroup>
                  <Input type="date" name="end-date" id="end-date" placeholder="End Date" value={endDate} onChange={(e) => updateEndDate(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
            <div className="text-right">
              <Button id="clearFilterBtn" color="secondary" className={styles.filterBtn} onClick={clearFilters}>
                Clear Filters
              </Button>
              <Button id="applyFilterBtn" color="primary" className={styles.filterBtn} onClick={() => filterAuditData(false)}>
                Apply Filter
              </Button>
              <Button id="applyFilterBtn" color="primary" className={styles.filterBtn} onClick={exportAuditData}>
                Export Data
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default AuditLogSearchBar;
