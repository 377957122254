import React, { useCallback } from 'react';
import { Switch } from 'react-router-dom';
import { UIProvider } from '../contexts/UIContext';
import Navigation from './Navbar';
import SideNav from './SideNav';
import styles from '../styles/archiveui.module.css';
import ErrorBoundary from '../errorboundary';
import AuthService from '../auth/authservice';
import { AccountDetail } from '../account/accountdetail';

export const NoAccounts = () => {
  const logout = useCallback(async () => {
    const auth = await AuthService.getInstance();
    await auth.logout();
  }, []);
  return (
    <UIProvider>
      <Navigation />
      <div className={styles.mainWrapper}>
        <SideNav logout={logout} />
        <div className={styles.mainContainer}>
          <div className={styles.mainContainerBody}>
            <Switch>
              <ErrorBoundary>
                <AccountDetail initialAccount={true} />
              </ErrorBoundary>
            </Switch>
          </div>
        </div>
      </div>
    </UIProvider>
  );
};
