import AuthService from '../auth/authservice';

const SANDBOX_URL = process.env.REACT_APP_SANDBOX_API_URL;

export const getBaseApiUrl = (): string => {
  if (process.env.REACT_APP_SANDBOX_ENV === 'SANDBOX' && SANDBOX_URL) {
    return SANDBOX_URL;
  }
  return '';
};

export const getAuthToken = async (accountId?: string) => {
  const auth = await AuthService.getInstance();
  const token = await auth.getAuthToken();
  if (token) {
    return `Bearer ${token}`;
  }
  return '';
};
