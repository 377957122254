import React, { useEffect, useState } from 'react';
import { Button, Card, Col, CustomInput, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { Document, QueuedDownload } from '../../types/document';

interface QueuedDownloadsProps {
  data: QueuedDownload[];
  fetchData: (downloaded: boolean) => void;
  handleQueuedDownload: (downloadId: string) => void;
}

export const QueuedDownloads = ({ data, fetchData, handleQueuedDownload }: QueuedDownloadsProps) => {
  const handleQueuedDownloadClick = (event: any, downloadId: string) => {
    event.preventDefault();
    handleQueuedDownload(downloadId);
  };

  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'Status',
          accessor: 'status',
        },
        {
          Header: 'File Name',
          accessor: 'fileName',
          Cell: ({ row, value }: { row: any; value: string }) => {
            return (
              <a href="#" onClick={(event) => handleQueuedDownloadClick(event, `${row.original.id}`)}>
                {value}
              </a>
            );
          },
        },
        {
          Header: 'Created Date',
          accessor: 'created',
          Cell: ({ value }) => {
            return value ? new Date(value).toLocaleDateString('en-US') : '-';
          },
        },
        {
          Header: 'Downloaded Date',
          accessor: 'downloaded',
          Cell: ({ value }) => {
            return value ? new Date(value).toLocaleDateString('en-US') : '-';
          },
        },
        {
          Header: 'Expiration Date',
          accessor: 'expires',
          Cell: ({ value }) => {
            return value ? new Date(value).toLocaleDateString('en-US') : '-';
          },
        },
      ] as Column<QueuedDownload>[],
    [],
  );
  const [showDownloaded, setShowDownloaded] = useState(false);
  const { headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  useEffect(() => {
    fetchData(showDownloaded);
  }, [showDownloaded]);

  return (
    <>
      <Row>
        <Col sm="12" md={{ size: 10, offset: 1 }} style={{ float: 'right' }}>
          <CustomInput
            id="showDownloaded"
            label="Show Downloaded"
            type="switch"
            checked={showDownloaded}
            onChange={() => {
              setShowDownloaded(!showDownloaded);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="12" md={{ size: 10, offset: 1 }}>
          <Table size="sm" id="QueuedDownloadsTable" responsive bordered>
            <thead>
              {headerGroups.map((headerGroup) => {
                return (
                  <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.getHeaderProps().key}>
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                      </th>
                    ))}
                  </tr>
                );
              })}
            </thead>
            <tbody>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  /* eslint-disable react/prop-types */
                  <tr {...row.getRowProps()} key={row.original.id}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} key={cell.getCellProps().key}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};
