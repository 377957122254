import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import AuthService from '../auth/authservice';
import { Link, withRouter } from 'react-router-dom';
import logo from '../main/scribforward_blue.png';
import scribblesLogo from './bird-icon-right-logo.png';

const ForgotPassword = () => {
  const [authService, setAuthService] = useState<AuthService>();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [destination, setDestination] = useState('');
  const [medium, setMedium] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log('forgot password auth service useeffect');
    const getAuthService = async () => {
      const service = await AuthService.getInstance();
      setAuthService(service);
      if (service.isAuthenticated()) {
        service.signinRedirect();
      }
    };
    getAuthService();
  }, []);

  const forgotPassword = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    if (authService) {
      await authService.forgotPassword(email, (data) => {
        console.log('details', data);
        setIsLoading(false);
        setDestination(data.CodeDeliveryDetails.Destination);
        setMedium(data.CodeDeliveryDetails.DeliveryMedium);
        setEmailSent(true);
      },
        (error) => {
        setErrorMessage(error.message);
        });
    }
  }

  const confirmPassword = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    if (newPassword !== passwordConfirm) {
      setErrorMessage('Passwords doesn\'t match');
      return;
    }
    if (authService) {
      await authService.confirmPassword(email, code, passwordConfirm, (error) => {
        setErrorMessage(error.message);
      });
    }
  }

  const EmailForm = (
    <Form>
      <h4>Forgot your password?</h4>
      <p>Enter your Email below and we will send a message to reset your password</p>
      <FormGroup>
        <Input type='email' id='email' value={email} placeholder='Email'
               onChange={(event) => setEmail(event.target.value)} />
      </FormGroup>
      <FormGroup>
        <Button color='warning' onClick={forgotPassword} disabled={isLoading}>Reset my password</Button>
        <Link to='/' style={{ float: 'right' }}>Cancel</Link>
      </FormGroup>
    </Form>
  );

  const CodeForm = (
    <Form>
      <p>{`We have sent a password reset code by ${medium} to ${destination}. Enter it below to reset your password.`}</p>
      <FormGroup>
        <Label for="code">Code</Label>
        <Input type='password' id='code' value={code}
               onChange={(event) => setCode(event.target.value)} />
      </FormGroup>
      <FormGroup>
        <Label for="newPassword">New Password</Label>
        <Input type='password' id='newPassword' value={newPassword}
               onChange={(event) => setNewPassword(event.target.value)} onKeyUp={() => setShowAlert(true)} />
      </FormGroup>
      <FormGroup>
        <Label for="passwordConfirm">Enter New Password Again</Label>
        <Input type='password' id='passwordConfirm' value={passwordConfirm}
               onChange={(event) => setPasswordConfirm(event.target.value)} onKeyUp={() => setShowAlert(true)} />
      </FormGroup>
      {showAlert ?
        <Alert color="info" style={{ fontSize: '13px' }}>
        Password must contain a lower case letter<br/>
        Password must contain an upper case letter<br/>
        Password must contain a number<br/>
        Password must contain at least 8 characters<br/>
        Passwords must match<br/>
        Password must not contain a leading or trailing space<br/>
      </Alert> : ''}
      <FormGroup>
        <Button color='success' onClick={confirmPassword} disabled={isLoading}>Change password</Button>
        <Link to='/' style={{ float: 'right' }}>Cancel</Link>
      </FormGroup>
    </Form>
  );

  return (
    <div>
      <Container style={{ paddingTop: '5em' }}>
        <Row>
          <Col sm='12' md={{ size: 4, offset: 4 }}>
            <img src={logo} style={{ height: '120px', top: 0, left: 0, display: 'flex' }} alt='' />
          </Col>
        </Row>
        <Row style={{ marginTop: '3em' }}>
          <Col sm='12' md={{ size: 4, offset: 4 }}>
            {errorMessage ? <Alert color='danger'>{errorMessage}</Alert> : ''}
          </Col>
        </Row>
        <Row>
          <Col sm='12' md={{ size: 4, offset: 4 }}>
            {emailSent ? CodeForm : EmailForm}
          </Col>
        </Row>
        <Row>
          <Col sm='12' md='3'>
          </Col>
          <Col sm='12' md={{ size: 6, offset: 3 }}>
            <img src={scribblesLogo} width='150px' className='pull-right' alt='Scribbles logo' />
          </Col>
          <Col sm='12' md='3'>
          </Col>
        </Row>
      </Container>
      <footer>
        <p>
          <a href='https://scribsoft.com/customer-responsibilities' target='_blank'>Terms of Use</a>
          <a href='https://scribsoft.com/privacy-policy' target='_blank' style={{ marginLeft: '3em' }}>Privacy
            Policy</a>
        </p>
      </footer>
    </div>
  );
}

export default withRouter(ForgotPassword);