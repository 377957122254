import fetch from 'node-fetch';
import { getBaseApiUrl } from '../api/helpers';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';

export default class AuthService {
  UserPool: CognitoUserPool;
  userSession?: CognitoUserSession;
  cognitoUser?: CognitoUser;
  redirectUri: string;
  authority?: string;
  client_id?: string;

  constructor(config: any) {
    this.UserPool = new CognitoUserPool({ ...config });

    console.log(`new AuthService created... ${JSON.stringify(config, null, 2)}`);
    this.redirectUri = config.redirect_uri;
    this.authority = config.authority;
    this.client_id = config.client_id;
  }

  static _instance: AuthService;

  static getInstance = async (): Promise<AuthService> => {
    if (!this._instance) {
      const response = await (await fetch(`${getBaseApiUrl()}/v1/api/forward/oidc_configuration`)).json();
      console.log(`getInstance got response: ${JSON.stringify(response, null, 2)}`);
      if (process.env.NODE_ENV === 'development') {
        response.redirect_uri = 'http://localhost:3000';
        response.post_logout_redirect_uri = 'http://localhost:3000';
      }
      const poolData = {
        UserPoolId: response.userPoolId,
        ClientId: response.client_id,
        redirect_uri: response.redirect_uri,
      };
      this._instance = new AuthService(poolData);
    }
    return this._instance;
    //get oidc config
    //return instance
  };

  getAuthToken = async () => {
    if (window.location.href.indexOf('embedded_') >= 0 && localStorage.getItem('integration_token')) {
      const token = localStorage.getItem('integration_token');
      if (token) {
        return token;
      }
    }
    if (!this.UserPool.getCurrentUser() && (process.env.NODE_ENV !== 'development' || process.env.REACT_APP_SANDBOX_ENV === 'SANDBOX')) {
      return await this.signinRedirect();
    } else {
      const cognitoUser = this.UserPool.getCurrentUser();
      return cognitoUser?.getSession((err: Error, session: CognitoUserSession | null) => {
        if (err) {
          console.error(err);
          return;
        }
        if (session) {
          return session.getAccessToken().getJwtToken();
        }
      });
    }
  };

  parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = async () => {
    localStorage.setItem('prevLocation', window.location.href);
    window.location.replace(this.redirectUri);
  };

  isAuthenticated = () => {
    const session = sessionStorage.getItem(`oidc.user:${this.UserPool.getUserPoolId()}:${this.client_id}`) || '';
    if (!session) return false;
    const oidcStorage = JSON.parse(sessionStorage.getItem(`oidc.user:${this.UserPool.getUserPoolId()}:${this.client_id}`) || '');
    return !!oidcStorage && !!oidcStorage.accessToken;
  };

  login = async (email: string, password: string, onError: (error: string) => void, onNewPasswordRequired: (userAttributes: any) => void) => {
    const user = new CognitoUser({
      Username: email.toLowerCase(),
      Pool: this.UserPool,
    });

    this.cognitoUser = user;

    const authDetails = new AuthenticationDetails({
      Username: email.toLowerCase(),
      Password: password,
    });

    //user.setAuthenticationFlowType('USER_PASSWORD_AUTH');

    user.authenticateUser(authDetails, {
      onSuccess: (session) => {
        localStorage.setItem('id_token', JSON.stringify(session.getIdToken()));
        sessionStorage.setItem(`oidc.user:${this.UserPool.getUserPoolId()}:${this.client_id}`, JSON.stringify(session));
        this.userSession = session;
        this.signinRedirect();
      },
      onFailure: (err) => {
        console.error('onFailure', err);
        onError(err.message);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        delete userAttributes.email_verified;
        delete userAttributes.email;
        onNewPasswordRequired(userAttributes);
      },
    });
  };

  completeNewPasswordChallenge = async (newPassword: string, userAttributes: any, onError: (error: string) => void) => {
    if (!this.cognitoUser) {
      this.cognitoUser = new CognitoUser({
        Username: userAttributes.email,
        Pool: this.UserPool,
      });
    }

    this.cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
      onSuccess: (session) => {
        this.signinRedirect();
      },
      onFailure: (err) => {
        console.error('onFailure', err);
        onError(`${err.message} Please refresh the page to try again.`);
      },
    });
  };

  forgotPassword = async (email: string, onSuccess: (data: any) => void, onError: (error: Error) => void) => {
    this.cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.UserPool,
    });
    this.cognitoUser.forgotPassword({
      onSuccess: (data: any) => {
        console.log('SUCCESS', data);
      },
      onFailure: (err: any) => {
        onError(err);
      },
      inputVerificationCode: (data: any) => {
        onSuccess(data);
      },
    });
  };

  confirmPassword = async (email: string, code: string, newPassword: string, onError: (error: Error) => void) => {
    this.cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.UserPool,
    });
    this.cognitoUser.confirmPassword(code, newPassword, {
      onSuccess: () => {
        this.signinRedirect();
      },
      onFailure: (err) => {
        console.log(err);
        onError(err);
      },
    });
  };

  logout = async () => {
    localStorage.removeItem('id_token');
    sessionStorage.removeItem(`oidc.user:${this.UserPool.getUserPoolId()}:${this.client_id}`);
    const cognitoUser = this.UserPool.getCurrentUser();
    cognitoUser?.signOut();
    await this.signinRedirect();
  };
}
