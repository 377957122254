import moment from 'moment';
import { AuditEntry } from '../types/audit';
import { downloadCsv } from '../utils/fileutils';

interface ExportAuditDataInterface {
  DATE_TIME: string;
  STUDENT_NAME: string;
  ORDER_ID: string;
  USER_EMAIL: string;
  ACTION: string;
  DESCRIPTION: string;
}

function ExportAuditData(audits: AuditEntry[]): void {
  const auditsData = audits.map((audit) => {
    return {
      DATE_TIME: moment(audit.time).format('MM/DD/yyyy HH:mm'),
      STUDENT_NAME: audit.studentName ? audit.studentName : '',
      ORDER_ID: audit.orderId ? audit.orderId : '',
      USER_EMAIL: audit.actor.email,
      ACTION: audit.action,
      DESCRIPTION: audit.description,
    };
  });
  const csv = convertToCSV(auditsData);
  downloadCsv('auditexportdata.csv', csv);
}

function convertToCSV(data: ExportAuditDataInterface[]): string {
  const header = Object.keys(data[0]).join(',');
  const rows = data.map((d) => `${d.DATE_TIME},${d.STUDENT_NAME},${d.ORDER_ID},${d.USER_EMAIL},${d.ACTION},${d.DESCRIPTION}`).join('\n');
  return `${header}\n${rows}`;
}

export default ExportAuditData;
