import React from 'react';
import { useHistory } from 'react-router';
import { Alert, Button } from 'reactstrap';

const NotAuthorized = () => {
  const history = useHistory();
  return (
    <div style={{ marginTop: '1em' }}>
      <Alert color="danger">
        <h4 className="alert-heading">Not Authorized</h4>
        <p>This page contains information that you do not have access to.</p>
        <hr />
        <p className="mb-0">If you think there is an error with your credentials, talk with your administrator.</p>
      </Alert>
      <Button
        onClick={() => {
          history.push('/');
        }}
      >
        Go Home
      </Button>
    </div>
  );
};

export default NotAuthorized;
