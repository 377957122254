import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useTable, useSortBy, usePagination, Column } from 'react-table';
import { User } from '../../types/user';
import { Card, Table, Input, Button, Alert } from 'reactstrap';
import { getUser } from '../../api/users';
import styles from '../../styles/archiveui.module.css';
import SearchBar from '../userlisttable/SearchBar';
import { AiFillEdit } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { StatusFilter } from '../userlist';
import AccessControl from '../userprofile/accesscontrol';
import { userHasAccess } from '../userprofile/user';
import { useUserContext } from '../../contexts/UserContext';

interface UserTableProps {
  users: User[];
  accountId: string;
  fetchUsers?: () => Promise<void>;
  subTable: boolean;
  statusFilter?: StatusFilter;
  updateStatusFilter?: Dispatch<SetStateAction<StatusFilter>>;
  searchText?: string;
  updateSearchText?: Dispatch<SetStateAction<string>>;
  adminList?: boolean;
}

const UserTable = ({ users, accountId, fetchUsers, subTable, statusFilter, updateStatusFilter, searchText, updateSearchText, adminList }: UserTableProps) => {
  const history = useHistory();
  const {
    profile: { permissions, user },
  } = useUserContext();
  const [data, setData] = useState<User[]>([]);

  console.log('users', users);
  useEffect(() => {
    if (subTable) {
      const stringUsers = users.map((user) => {
        const stringUser = "'" + user + "'";
        return stringUser.replace(/['"]+/g, '');
      });
      Promise.all(stringUsers.map((user) => getUser(user, accountId))).then((resolvedValues) => {
        resolvedValues.forEach((value: User) => {
          setData((userData) => [...userData, value]);
        });
      });
    } else {
      setData(users);
    }
  }, [users]);

  const formatPhoneNumber = (str: string) => {
    //Filter only numbers from the input
    const cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return str;
  };

  const columns: any = React.useMemo(() => {
    const cols = [
      {
        Header: 'Last Name',
        accessor: 'familyName', // accessor is the "key" in the data
      },
      {
        Header: 'First Name',
        accessor: 'givenName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        /* eslint-disable react/prop-types */
        Cell: (props) => formatPhoneNumber(props.value),
      },
    ] as Column<User>[];

    if (userHasAccess([{ permissionId: 'users', action: 'update' }], accountId, user, permissions)) {
      cols.push({
        Header: 'Actions',
        accessor: 'status',
        // eslint-disable-next-line react/display-name
        Cell: (cellProps) => (
          <>
            <AccessControl
              requiredPermissionList={[
                { permissionId: 'users', action: 'update' },
                { permissionId: 'roles', action: 'read' },
              ]}
            >
              <AiFillEdit id="edit" color="#212529" />
            </AccessControl>
          </>
        ),
      });
    }
    return cols;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data, initialState: { pageIndex: 0 } }, useSortBy, usePagination);

  const onRowClick = (cell: User | any) => {
    if (cell.column.Header === 'Actions') {
      history.push(`/userdetail/${cell.row.original.id}${adminList ? '/true' : ''}`);
    }
  };

  const table = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (table != undefined) {
    table.style.borderRadius = '.5em';
  }

  return (
    <div className={subTable ? styles.userTableContainerRoles : styles.userTableContainer}>
      {updateStatusFilter && updateSearchText && (
        <SearchBar
          accountId={accountId}
          searchText={searchText}
          updateSearchText={updateSearchText}
          statusFilter={statusFilter}
          updateStatusFilter={updateStatusFilter}
          subListStyle={subTable}
          fetchUsers={fetchUsers}
          adminList={adminList}
        />
      )}
      <AccessControl permissionId={'users'} action={'read'}>
        {users.length > 0 ? (
          <>
            <Card className={styles.tableContainer}>
              <Table className={styles.archiveTable} striped hover size="sm" id="auditTable" responsive {...getTableProps()}>
                <thead className={styles.tableHeader}>
                  {headerGroups.map((headerGroup) => (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        if (column.Header === 'Last Name') {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.IdHeader}>
                              {column.render('Header')}
                              {/* Add a sort direction indicator */}
                              <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                            </th>
                          );
                        } else if (column.Header === 'Actions') {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.actionsHeader}>
                              {column.render('Header')}
                              {/* Add a sort direction indicator */}
                              <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                            </th>
                          );
                        } else {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.th}>
                              {column.render('Header')}
                              {/* Add a sort direction indicator */}
                              <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                            </th>
                          );
                        }
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          if (cell.column.Header === 'Last Name') {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <td
                                style={{ paddingLeft: '1.25em' }}
                                onClick={() => {
                                  onRowClick(cell);
                                }}
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          } else if (cell.column.Header === 'Actions') {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <td
                                className={styles.actionsCell}
                                onClick={() => {
                                  onRowClick(cell);
                                }}
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          } else {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <td
                                onClick={() => {
                                  onRowClick(cell);
                                }}
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell')}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
            <div className="pagination" style={{ justifyContent: 'end', marginTop: '.5em' }}>
              <div style={{ marginRight: '.5em' }}>
                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </Button>{' '}
                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
                </Button>{' '}
                <Button onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </Button>{' '}
                <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  {'>>'}
                </Button>{' '}
              </div>
              <div style={{ marginRight: '.5em' }}>
                <span>
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{' '}
                </span>
              </div>
              <Input
                style={{ maxWidth: '10em' }}
                type="select"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </Input>
            </div>
          </>
        ) : (
          <Alert color="warning" className={styles.noResultsAlert}>
            No results found...
          </Alert>
        )}
      </AccessControl>
    </div>
  );
};

export default UserTable;
