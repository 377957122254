import React, { useCallback, useEffect, useState } from 'react';
import { Column, SortingRule, usePagination, useSortBy, useTable } from 'react-table';
import { Button, Card, Col, Input, Row, Table, CustomInput } from 'reactstrap';
import { CRMStatusDisplayValues, Document } from '../../types/document';
import { getDocumentContent } from '../../api/document';
import { toastError } from '../../toast';
import { useUserContext } from '../../contexts/UserContext';
import DownloadModal from './downloadModal';
import ConfirmationModal from './confirmationModal';
import styles from '../../styles/archiveui.module.css';
import { PremiumAccessControl } from '../../componentutils/PremiumAccessControl';
import CRMStatusModal from './CRMModal';
import { updateCRMStatus } from '../../api/crmintegration';
import { toast } from 'react-toastify';

interface DocumentTableProps {
  documents?: Document[];
  selectedPageSize: number;
  totalPages: number;
  totalCount: number;
  updatePageSize: (size: number) => void;
  updatePage?: (page: number) => void;
  controlledPage?: number;
  currentSort?: [any];
  updateSort?: (newSort: any) => void;
  refreshDocuments: () => void;
}

const DocumentTable = ({
  documents,
  selectedPageSize,
  totalPages,
  totalCount,
  updatePage,
  updatePageSize,
  controlledPage,
  currentSort,
  updateSort,
  refreshDocuments,
}: DocumentTableProps): JSX.Element => {
  documents = documents?.map((document: Document, index) => ({
    ...document,
    selected: false,
    position: index,
  }));

  let selectAll = false;
  const [errorMessage, setErrorMessage] = useState('');

  const { currentAccount } = useUserContext();
  const updateSortInternal = useCallback(
    (field: SortingRule<Document>[]) => {
      if (!updateSort) return;
      updateSort(field);
    },
    [updateSort],
  );

  const warningText = 'Please, select one or more files';

  const handleDocumentClick = async (event: any, client: string, linkId: string, contentType: string, attachmentOrderId: string, position: number) => {
    event.preventDefault();
    const object = await toastError(getDocumentContent(currentAccount.accountId, linkId), 'Error loading pdf document');
    if (!object) {
      return;
    }

    const list = data.map((document: Document) => {
      if (document.attachmentOrderId == attachmentOrderId && document.position == position) {
        document.lastViewDate = object.lastViewDate;
      }
      return document;
    });

    setData(list);
    const blob = new Blob([object.content], { type: contentType });
    const url = URL.createObjectURL(blob);
    const newWin = window.open(url);

    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
      //POPUP BLOCKED
      console.log('POPUP BLOCKED');
    }
  };

  const handleCheckboxHeader = () => {
    selectAll = !selectAll;
    const list = data.map((document: Document) => {
      document.selected = selectAll;
      return document;
    });
    setData(list);
  };

  const handleCheckbox = (value: any, position: number) => {
    const list = data?.map((document: Document) => {
      if (document.id === value && document.position === position) {
        document.selected = !document.selected;
      }
      return document;
    });
    const selectedList = list.filter((document) => document.selected == true);
    const enableList = list.filter((document) => document.expires >= Date.now());
    selectAll = selectedList.length === enableList.length;
    setData(list);
  };

  const [newCRMStatus, setNewCRMStatus] = useState<string>();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [CRMModal, setCRMModal] = useState(false);
  const toggleCRM = () => setCRMModal(!CRMModal);

  const [modalConfirmation, setConfirmationModal] = useState(false);
  const toggleModalConfirmation = () => setConfirmationModal(!modalConfirmation);

  const [downloadMessage, setDownloadMessage] = useState('');

  const getDocumentStatus = (doc: Document) => {
    if (doc.expires < Date.now()) {
      return 'Expired';
    } else {
      if (doc.downloaded) {
        return 'Complete';
      } else {
        if (doc.lastViewDate) {
          return 'Reviewed';
        } else {
          return 'Received';
        }
      }
    }
  };

  const getCrmStatus = (doc: Document) => {
    if (!doc.metaData || !doc.metaData.crmStatus) return 'N/A';
    return CRMStatusDisplayValues[doc.metaData.crmStatus];
  };

  const getOrderId = (doc: Document, value: any) => {
    if (doc.expires < Date.now()) {
      return <div>{value}</div>;
    } else {
      return (
        <a
          href="#"
          onClick={(event) => handleDocumentClick(event, doc.client, `${doc.client}:${doc.id}`, doc.contentType, doc.attachmentOrderId, doc.position)}
        >
          {value}
        </a>
      );
    }
  };

  const getCheckBox = (document: Document) => {
    if (document.expires < Date.now()) {
      return <CustomInput type="checkbox" id={`selectDocument-${document.position}`} disabled />;
    } else {
      return (
        <CustomInput
          type="checkbox"
          id={`selectDocument-${document.position}`}
          name={`selectDocument-${document.position}`}
          checked={document.selected}
          value={document.id}
          onChange={(e) => {
            handleCheckbox(e.target.value, document.position);
          }}
        />
      );
    }
  };

  const [data, setData] = useState(documents || []);

  const columns = React.useMemo(
    () =>
      [
        {
          id: 'checkboxHeader',
          Header: () => (
            <CustomInput
              type="checkbox"
              id={'checkboxHeader'}
              label="All"
              checked={selectAll}
              onChange={(e) => {
                handleCheckboxHeader();
              }}
            />
          ),
          Cell: ({ row }: { row: any }) => {
            return getCheckBox(row.original);
          },
          disableSortBy: true,
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ row }: { row: any }) => {
            return getDocumentStatus(row.original);
          },
        },
        ...(currentAccount.crmStatus && currentAccount.crmStatus !== 'DISABLED'
          ? [
              {
                Header: 'Send to CRM',
                accessor: 'metaData.crmStatus',
                Cell: ({ row, value }: { row: any; value: string }) => {
                  return getCrmStatus(row.original);
                },
              },
            ]
          : []),
        {
          Header: 'Order ID',
          accessor: 'attachmentOrderId',
          Cell: ({ row, value }: { row: any; value: string }) => {
            return getOrderId(row.original, value);
          },
        },
        {
          Header: 'Application ID',
          accessor: 'submissionId',
        },
        {
          Header: 'First Name',
          accessor: 'metaData.firstname',
        },
        {
          Header: 'Middle Name',
          accessor: 'metaData.middlename',
        },
        {
          Header: 'Last Name',
          accessor: 'metaData.lastname',
        },
        {
          Header: 'Student Id',
          accessor: 'studentid',
        },
        {
          Header: 'DOB',
          accessor: 'metaData.dobvalue',
          Cell: ({ row }: { row: any }) => {
            return row.original.metaData.dob ? row.original.metaData.dob : '';
          },
        },
        {
          Header: 'District',
          accessor: 'metaData.districtname',
        },
        {
          Header: 'School',
          accessor: 'metaData.last_school_of_attendance',
        },
        {
          Header: 'Doc Type',
          accessor: 'documentType',
        },
        {
          Header: 'HS CEEB',
          accessor: 'metaData.hs_ceeb',
        },
        {
          Header: 'Date Received',
          accessor: 'created',
          Cell: ({ value }) => {
            return new Date(value).toLocaleDateString('en-US');
          },
        },
        {
          Header: 'Date Reviewed',
          accessor: 'lastViewDate',
          Cell: ({ value }) => {
            return value ? new Date(value).toLocaleDateString('en-US') : '';
          },
        },
        {
          Header: 'Date Downloaded',
          accessor: 'downloaded',
          Cell: ({ value }) => {
            return value ? new Date(value).toLocaleDateString('en-US') : '';
          },
        },
      ] as Column<Document>[],
    [],
  );
  const initPage = controlledPage ? controlledPage - 1 : 0;

  const {
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setSortBy,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
      initialState: { pageSize: selectedPageSize, pageIndex: initPage, sortBy: currentSort },
      manualSortBy: true,
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    if (pageSize !== selectedPageSize) updatePageSize(pageSize);
    if (!updatePage || !controlledPage || pageIndex + 1 === controlledPage) return;
    updatePage(pageIndex + 1);
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (currentSort) setSortBy(currentSort);
    if (sortBy !== currentSort) {
      updateSortInternal(sortBy);
    }
  }, [sortBy, currentSort]);

  const table = document.getElementsByClassName('table-responsive')[0] as HTMLElement;
  if (table != undefined) {
    table.style.borderRadius = '.5em';
  }

  const passDownloadDate = (downloadDate: number) => {
    const list = data?.map((document: Document) => {
      if (document.selected) {
        document.downloaded = downloadDate;
      }
      return document;
    });
    setData(list);
  };

  const outPutDownloadProcess = (message: string, downloadDate: number) => {
    setDownloadMessage(message);
    setConfirmationModal(true);
    if (downloadDate > 0) passDownloadDate(downloadDate);
  };

  const onCloseModal = () => {
    setModal(false);
  };

  const openDownloadModal = () => {
    const list = data.filter((document: Document) => document.selected);
    if (list.length > 0) {
      setErrorMessage('');
      setModal(true);
    } else {
      setErrorMessage(warningText);
    }
  };

  const crmStatusSubmit = async (newStatus: string) => {
    const docs = data.filter((document: Document) => document.selected);
    const showConfirm = newStatus === 'DO_NOT_SEND' && docs.filter((document) => !document.lastViewDate && !document.downloaded).length > 0;
    setNewCRMStatus(newStatus);
    if (showConfirm) {
      setDownloadMessage('One or more of these records have not been downloaded.\nAre you sure?');
      toggleModalConfirmation();
    } else {
      await updateCRMStatusInternal(newStatus);
    }
  };

  const updateCRMStatusInternal = async (newStatus?: string) => {
    console.log('newCRMStatus ', newCRMStatus);
    const status = newStatus ? newStatus : newCRMStatus;
    console.log('status', status);
    if (!status) return;
    await toast.promise(
      updateCRMStatus(
        currentAccount.accountId,
        data.filter((document: Document) => document.selected).map((document) => `${document.client}:${document.id}`),
        status,
      ),
      { success: 'CRM status updated.', pending: 'Updating status...', error: 'Unable to update crm status.' },
    );
    setNewCRMStatus(undefined);
    setCRMModal(false);
    refreshDocuments();
  };

  return (
    <>
      <Row>
        <Col>
          {errorMessage.length > 0 && (
            <div style={{ float: 'right' }} className={styles.errorMessageStyle}>
              {errorMessage}
            </div>
          )}
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '15px' }}>
          {data.filter((document: Document) => document.selected).length > 1 ? (
            <PremiumAccessControl highlight={true}>
              <Button
                id="btnDownload"
                name="btnDownload"
                style={{ float: 'right', marginBottom: '5px' }}
                className={styles.scrbBtnBlueMargin}
                onClick={() => {
                  openDownloadModal();
                }}
              >
                Download
              </Button>
            </PremiumAccessControl>
          ) : (
            <Button
              id="btnDownloadDisabled"
              name="btnDownloadDisabled"
              style={{ float: 'right', marginBottom: '5px' }}
              className={styles.scrbBtnBlueMargin}
              onClick={() => {
                openDownloadModal();
              }}
            >
              Download
            </Button>
          )}
          {currentAccount.crmStatus && currentAccount.crmStatus !== 'DISABLED' && (
            <Button
              id="btnCRMStatus"
              name="btnCRMStatus"
              style={{ float: 'right', marginBottom: '5px' }}
              className={styles.scrbBtnOrange}
              onClick={() => {
                toggleCRM();
              }}
              disabled={data.filter((document: Document) => document.selected).length == 0}
            >
              CRM
            </Button>
          )}
        </Col>
      </Row>
      <Card>
        <DownloadModal
          modal={modal}
          toggle={toggle}
          documentsData={data.filter((document: Document) => document.selected)}
          accountId={currentAccount.accountId}
          outPutDownloadProcess={outPutDownloadProcess}
          onCloseModal={onCloseModal}
        />
        <CRMStatusModal modal={CRMModal} toggle={toggleCRM} onSubmit={crmStatusSubmit} />
        <ConfirmationModal
          modal={modalConfirmation}
          toggle={toggleModalConfirmation}
          message={downloadMessage}
          onConfirm={newCRMStatus ? updateCRMStatusInternal : undefined}
        />
        <Table striped hover size="sm" id="documentTable" responsive>
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} key={column.getHeaderProps().key}>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                    </th>
                  ))}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                /* eslint-disable react/prop-types */
                <tr {...row.getRowProps()} key={row.original.id}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} key={cell.getCellProps().key}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
      {pageCount > 1 && (
        <Row style={{ marginTop: '.5em' }}>
          <Col>{totalCount} records</Col>
          <Col className="pagination" style={{ justifyContent: 'end' }}>
            <div style={{ marginRight: '.5em' }}>
              <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </Button>{' '}
              <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </Button>{' '}
              <Button onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </Button>{' '}
              <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
              </Button>{' '}
            </div>
            <div style={{ marginRight: '.5em' }}>
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
            </div>
            <Input
              style={{ maxWidth: '10em' }}
              type="select"
              value={selectedPageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
      )}
    </>
  );
};

export default DocumentTable;
