import { UserPermission } from '.';
import { PermissionActions, PermissionId } from '../../types/role';
import { User } from '../../types/user';
import { useHistory } from 'react-router';

interface AccessControlPermission {
  permissionId: PermissionId;
  action: keyof PermissionActions;
}

export const routePermissions: Record<string, { hardPermissions: AccessControlPermission[]; softPermissions?: AccessControlPermission[] }> = {
  '/inbox': {
    hardPermissions: [
      { permissionId: 'inbox', action: 'read' },
      { permissionId: 'inbox', action: 'create' },
    ],
    softPermissions: [],
  },
  '/users': {
    hardPermissions: [],
    softPermissions: [
      { permissionId: 'users', action: 'read' },
      { permissionId: 'users', action: 'create' },
    ],
  },
  '/roles': {
    hardPermissions: [],
    softPermissions: [
      { permissionId: 'roles', action: 'read' },
      { permissionId: 'roles', action: 'create' },
    ],
  },
  '/auditlog': {
    hardPermissions: [],
    softPermissions: [
      { permissionId: 'auditlog', action: 'read' },
      { permissionId: 'auditlog', action: 'create' },
    ],
  },
};

export const redirectUser = (
  accountId: string,
  user: User,
  permissions: Record<string, Record<string, UserPermission>>,
  history: ReturnType<typeof useHistory>,
) => {
  for (const route in routePermissions) {
    const { hardPermissions, softPermissions } = routePermissions[route];
    if (userAccessPermitted(accountId, user, permissions, hardPermissions, softPermissions)) {
      history.push(route);
      return;
    }
  }
  throw new Error('User has no access.');
};

export const userAccessPermitted = (
  accountId: string,
  user: User,
  permissions: Record<string, Record<string, UserPermission>>,
  requiredPermissions: AccessControlPermission[],
  softPermissionList?: AccessControlPermission[],
): boolean => {
  if (user.isScribblesAdmin) return true;

  if (requiredPermissions.length === 0 && (!softPermissionList || softPermissionList.length === 0)) {
    // if no permissions passed, component is scribbles admin only
    return false;
  }

  let requiredCheck = true;
  const accountPermissions = permissions[accountId];
  for (const { permissionId, action } of requiredPermissions) {
    let allowedActions;
    if (accountPermissions && accountPermissions[permissionId] != undefined) {
      allowedActions = accountPermissions[permissionId].actions;
    }

    if (!allowedActions) {
      requiredCheck = false;
      break;
    }

    if (allowedActions[action] !== 'ALLOW') {
      requiredCheck = false;
      break;
    }
  }

  if (!softPermissionList) {
    return requiredCheck;
  }

  let softCheck = true;
  for (const { permissionId, action } of softPermissionList) {
    let allowedActions;
    if (accountPermissions && accountPermissions[permissionId] != undefined) {
      allowedActions = accountPermissions[permissionId].actions;
    }

    if (!allowedActions) {
      softCheck = false;
      continue;
    }

    if (allowedActions[action] === 'ALLOW') {
      softCheck = true;
      break;
    } else {
      softCheck = false;
    }
  }

  return requiredCheck && softCheck;
};

export const userHasAccess = (
  requiredPermissions: AccessControlPermission[],
  accountId: string,
  user: User,
  permissions: Record<string, Record<string, UserPermission>>,
  softPermissionList?: AccessControlPermission[],
) => {
  return userAccessPermitted(accountId, user, permissions, requiredPermissions, softPermissionList);
};
