import { useMemo } from 'react';
import { useUserContext } from '../../contexts/UserContext';
import { PermissionActions, PermissionId } from '../../types/role';
import { userAccessPermitted } from './user';

interface AccessControlPermission {
  permissionId: PermissionId;
  action: keyof PermissionActions;
}

interface BaseAccessControlProps {
  children: JSX.Element;
  softPermissionList?: AccessControlPermission[]; //user must have access to at least one permission in this list
  alternateChildren?: JSX.Element;
}

interface PropsWithSingle extends BaseAccessControlProps {
  permissionId: PermissionId;
  action: keyof PermissionActions;
  requiredPermissionList?: never;
}

interface PropsWithList extends BaseAccessControlProps {
  requiredPermissionList?: AccessControlPermission[]; //user must have access to at all permissions in this list
  permissionId?: never;
  action?: never;
}

const AccessControl = ({ children, permissionId, action, requiredPermissionList, softPermissionList, alternateChildren }: PropsWithList | PropsWithSingle) => {
  const {
    profile: { permissions, user },
    currentAccount: { accountId },
  } = useUserContext();

  const requiredPermissions = requiredPermissionList ?? [];
  if (permissionId && action) {
    requiredPermissions.push({ permissionId, action });
  }

  const showChildren = useMemo(() => {
    return userAccessPermitted(accountId, user, permissions, requiredPermissions, softPermissionList);
  }, [accountId, requiredPermissions, softPermissionList]);

  if (showChildren) {
    return children;
  } else {
    return alternateChildren ?? null;
  }
};

export default AccessControl;
