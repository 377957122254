import React from 'react';
import { PrivateRoute } from '../auth/privateroute';
import { useUserContext } from '../contexts/UserContext';
import { LoadingComponent } from '../Loading';
import NotAuthorized from '../users/userprofile/notauthorized';
import AccountList from '../account/accountlist';
import AccountDetail from '../account/accountdetail';
import UserDetail from '../users/userdetail/index';
import UserProfilePage from '../users/userprofile';
import FailedLogin from '../auth/failedlogin';
import { Route, useHistory } from 'react-router';
import { redirectUser } from '../users/userprofile/user';
import RoleDetail from '../roles/roledetail';
import RoleList from '../roles/rolelist';
import UserList from '../users/userlist';
import { PremiumAccessControl } from '../componentutils/PremiumAccessControl';
import DocumentSearch from '../document/documentsearch';
import AccessControl from '../users/userprofile/accesscontrol';
import AuditLog from '../auditlog';
import CRMConfig from '../crmintegration';

export const Routes = () => {
  const loading = false;
  const {
    profile: { permissions, user },
    currentAccount: { accountId },
  } = useUserContext();

  const history = useHistory();
  if (history.location.pathname === '/') {
    redirectUser(accountId, user, permissions, history);
  }
  return (
    <LoadingComponent isLoading={loading}>
      <PrivateRoute path="/" exact component={(props: string) => <DocumentSearch />} />
      <PrivateRoute path="/inbox" exact component={(props: string) => <DocumentSearch />} />

      <PrivateRoute path="/userprofile" component={UserProfilePage} />
      <PrivateRoute path="/notauthorized" component={NotAuthorized} />

      <PrivateRoute path="/users" exact component={(props: string) => <UserList />} />
      <PrivateRoute path="/userdetail/:id?/:adminList?" component={(props: string) => <UserDetail />} />
      <PremiumAccessControl highlight={false}>
        <PrivateRoute path="/roles" exact component={(props: string) => <RoleList />} />
      </PremiumAccessControl>
      <PrivateRoute path="/auditlog" exact component={(props: string) => <AuditLog />} />
      <PrivateRoute path="/roledetail/:id?" exact component={(props: string) => <RoleDetail />} />
      <Route path="/crmconfig" exact component={() => <CRMConfig />} />
      <Route path="/failedlogin" component={() => <FailedLogin message="You must be invited to join ScribForward" />} />
      <AccessControl>
        <>
          <PrivateRoute path="/adminusers" exact component={(props: string) => <UserList adminList={true} />} />
          <Route path="/accountlist" exact component={AccountList} />
          <Route path="/accountdetail/:accountId?" component={AccountDetail} />
        </>
      </AccessControl>
    </LoadingComponent>
  );
};
